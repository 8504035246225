import React from 'react'
import './footerPhone.css'
import useWindowSize from '../UseWindowSize';

const FooterPhone = () => {
    const size = useWindowSize()
  return (  
    <div className='contenu-footer-phone'>
        <p>
                Ô pays des Nail, l'élégance jusqu'au bout des ongles 
        </p> 
    </div>
)
}

export default FooterPhone