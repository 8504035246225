
import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import PresentationDesk from '../component/PresentationDesk'
import ServiceDesk from '../component/ServiceDesk'
import GalleryDesk from '../component/GalleryDesk'
import ContactDesk from '../component/ContactDesk'
import AboutDesk from '../component/AboutDesk'
import NavBarDesk from '../component/NavBarDesk'
import FooterDesk from '../component/FooterDesk'
import Home from '../../Home'
import './appWebDesk.css'

const AppWebDesktop = () =>{
  const [etatGestion, setEtatGestion] = useState(false);
  
  
  return (
    <>
     <BrowserRouter>
      <div className="app-container">
         {!etatGestion && <NavBarDesk />}
              <div className="content">
                  <Routes>       
                      <Route path='/' element={<PresentationDesk setEtatGestion={setEtatGestion} />} />
                      <Route path='/service' element={<ServiceDesk setEtatGestion={setEtatGestion} />} />
                      <Route path='/gallery' element={<GalleryDesk setEtatGestion={setEtatGestion} />}/>
                      <Route path='/about' element={<AboutDesk setEtatGestion={setEtatGestion} />} />   
                      <Route path='/contact' element={<ContactDesk setEtatGestion={setEtatGestion} />} />  
                      <Route path='/gestion' element={<Home setEtatGestion={setEtatGestion} />} /> 
                      <Route path='/login' element={<Home setEtatGestion={setEtatGestion} />} />      
                  </Routes>
              </div>
          <div className='app-footer'>
          {!etatGestion && <FooterDesk />}
          </div>
      </div>
    </BrowserRouter>
    </>
  )
}


  
 

export default AppWebDesktop