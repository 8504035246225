import React from 'react';
import insta from '../assets/instagram.png'
import facebook from '../assets/facebook.png'
import './contactDesk.css';

const Contact = () => {
  return (
    <div className="container-composant">
        <div className='bloc-contact'>
            <div className='card-contact'>
                <h2>Contactez-Nous</h2>
                <p> Avenue de Paris, 97400 Saint Denis</p>
                <p> La Réunion</p>
                <p> +262 96 00 00  /  +692 24 00 00 </p>
                <p> contact@alyce-nail.re</p>
                <div className="social-media">
                    <div className='div-insta'>
                        <img className='img-insta' src={insta} alt='imgFacebook'/>
                    </div>
                    <div className='div-facebook'>
                        <img className='img-facebook' src={facebook} alt='imgFacebook'/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Contact;
