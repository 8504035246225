import React, { useEffect, useState, } from 'react';
import axios from 'axios';
import './modifClient.css';
import { useMonContexte } from '../MonContext';
import FicheClient from './FicheClient';
import { useNavigate } from 'react-router-dom';
import GestionClients from './GestionClients';
import AfficheClientVide from './AfficheClientVide';

const ModifAjoutClient = ({id}) => {

    const {adresseServeurBackEnd, ficheClient, setContenuPageCentrale, setContenuPageDroite,fichePersonnel, 
        setPageConnexion} =  useMonContexte();
    
    const [form, setForm] = useState(ficheClient);
    const navigate = useNavigate();
    const ficheNull = { id: null, nom: '', prenom: '', dateNaissance: '', telephone: '', adresse: '', codePostal: '', ville: '', mail: '' }


    const choixAjoutModif = () =>{
        if (id) {
            setForm(ficheClient)
        } else {
            setForm(ficheNull)
        }        
    }

      
    const handleChange = (e) => {
      const { name, value } = e.target;
      setForm((prevForm) => ({ ...prevForm, [name]: value }));
    };

    const handleAnnule = () => {        
      setContenuPageCentrale(<GestionClients/>)
      setContenuPageDroite(<AfficheClientVide/>) 
      };
  
  const handleSubmit = async (e) => {
    e.preventDefault();  
    try {
      const formattedDate = new Date(form.dateNaissance).toISOString().split('T')[0]; // Format ISO 8601 YYYY-MM-DD
      const formData = { ...form, dateNaissance: formattedDate };
      if (form.id) {
        await axios.put(`${adresseServeurBackEnd}/clients/${form.id}`, formData,{
          headers: {
              Authorization: `Bearer ${fichePersonnel.token}`,
              'Content-Type': 'application/json'
          }
        });
      } else {
        await axios.post(`${adresseServeurBackEnd}/clients`, formData,{
          headers: {
              Authorization: `Bearer ${fichePersonnel.token}`,
              'Content-Type': 'application/json'
          }
        });
  
      }
      setForm(ficheNull);
      setContenuPageCentrale(<GestionClients/>)
      setContenuPageDroite(<AfficheClientVide/>) 
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
        navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
        setPageConnexion(false)
      } else {
      console.error('Erreur lors de l\'enregistrement du client', error);
    }
    }
  };
  
  useEffect(() =>{
    choixAjoutModif();
  },[])

    return (
      <div className='bloc-modif-client'>
        {id ? <h1>Modification de la Fiche Client</h1> : <h1>Ajout d'un nouveau Client</h1>}
       <form className="clients-form" onSubmit={handleSubmit}>
            <input
              type="text"
              name="nom"
              placeholder="Nom"
              value={form.nom}
              onChange={handleChange}
              required
            />
           <input
              type="text"
              name="prenom"
              placeholder="Prénom"
              value={form.prenom}
              onChange={handleChange}
            />
            <input
              type="date"
              name="dateNaissance"
              placeholder="Date de Naissance"
              value={form.dateNaissance}
              onChange={handleChange}
            />
            <input
              type="text"
              name="telephone"
              placeholder="Téléphone"
              value={form.telephone}
              onChange={handleChange}
            />
            <input
              type="text"
              name="adresse"
              placeholder="Adresse"
              value={form.adresse}
              onChange={handleChange}
            />
            <input
              type="text"
              name="codePostal"
              placeholder="Code Postal"
              value={form.codePostal}
              onChange={handleChange}
            />
            <input
              type="text"
              name="ville"
              placeholder="Ville"
              value={form.ville}
              onChange={handleChange}
            />
            <input
            type="text"
            name="mail"
            placeholder="Email"
            value={form.mail}
            onChange={handleChange}
          />
          <div>
              <button type="submit">{form.id ? 'Modifier' : 'Ajouter'}</button>
              <button onClick={()=> handleAnnule()} >Annuler</button>
          </div>
          </form>
      </div>
    )
}

export default ModifAjoutClient