import React from 'react'
import Header from './Header'
import Connexion from './Connexion'
import './connexion.css'
import { useMonContexte } from '../MonContext'

const PageConnexion = () => {

  const {pageConnexion, setPageConnexion, affichage, setAffichage} =  useMonContexte();
  return (
    <div className='container-connexion' >
        <div className='header-connexion'>
            <Header/>
        </div>  
        <div>
            <Connexion/>
        </div>
    </div>
  )
}

export default PageConnexion