import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './saisieRdv.css';
import { useMonContexte } from '../MonContext';
import 'moment/locale/fr';
import Calendrier from '../Calendrier/Calendrier';
import FicheClient from '../Client/FicheClient';
import { useNavigate } from 'react-router-dom';
import PriseRdvPersonnel from './PriseRdvPersonnel';
import AffichageClientSelectionnee from '../PageTemplete/AffichageClientSelectionnee';

const SaisieRdvPersonnel = ({dateStr}) => {
    const { adresseServeurBackEnd, fichePersonnel, setFicheClient, ficheClient, setPageConnexion, setContenuPageCentrale, 
            setContenuPageDroite } = useMonContexte();


    const [selecNewClient, setSelecNewClient] = useState(false);
    const [clients, setClients] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState(null);

    const navigate = useNavigate();

    const fetchClients = async () => {
        try {
            const response = await axios.get(`${adresseServeurBackEnd}/clients`, {
                headers: { Authorization: `Bearer ${fichePersonnel.token}` }
            });
            if (Array.isArray(response.data)) {
                setClients(response.data);
                console.log(response.data)
            } else {
                console.error("La réponse de l'API n'est pas un tableau", response.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
                navigate('/gestion');
                setPageConnexion(false)
            } else {
                console.error('Erreur lors du chargement des clients', error);
            }
        }
    };


    const handleClientSelect = (event) => {
        const clientId = event.target.value;
        setSelectedClientId(clientId);
        const client = clients.find(c => c.id === parseInt(clientId, 10));
        if (client) {
            setFicheClient(client)
            console.log(client)
        } 
        setContenuPageDroite(<AffichageClientSelectionnee />);
        setContenuPageCentrale(<PriseRdvPersonnel dateStr={dateStr} />)
    };

    
    const [form, setForm] = useState({
        id: null,
        nom: '',
        prenom: '',
        dateNaissance: '',
        telephone: '',
        adresse: '',
        codePostal: '',
        ville: '',
        mail:''
    });

    
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setForm(prevForm => ({
            ...prevForm,
            [name]: value
        }));
    };


    const handleEnregistre = async () => {
        console.log(form);
        
        const formWithDate = {
            ...form,
            dateNaissance: new Date().toISOString().split('T')[0] // Utilise la date du jour si la date de naissance est vide
        };
    
        try {
            const response = await axios.post(`${adresseServeurBackEnd}/clients`, formWithDate, {
                headers: {
                    Authorization: `Bearer ${fichePersonnel.token}`,
                    'Content-Type': 'application/json'
                }
            });
    
            const nouvelId = response.data.id; // Récupérer le nouvel ID depuis la réponse
            console.log("Nouvel ID reçu:", nouvelId);
    
            // Mettre à jour l'ID dans le form
            const formWithNewId = {
                ...formWithDate,
                id: nouvelId
            };
            setForm(formWithNewId); // Mise à jour de l'état avec le nouvel ID
    
            // Mise à jour de ficheClient avec le nouveau form
            setFicheClient(formWithNewId);
    
            // Affichage et prise de rendez-vous après mise à jour
            setContenuPageDroite(<AffichageClientSelectionnee />);
            setContenuPageCentrale(<PriseRdvPersonnel dateStr={dateStr} />)
    
        } catch (error) {
            if (error.response && error.response.status === 403) {
                console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
                navigate('/gestion');
                setPageConnexion(false);
            } else {
                console.error('Erreur lors du chargement des clients', error);
            }
        }
    
        console.log(form); // Ceci montrera le form mis à jour avec le nouvel ID
    };
    

    useEffect(() =>{
        fetchClients()
    },[])



    return (
        <div className='container-saisie-rdv-personnel'>
            {!selecNewClient && <div className='bloc-selection-base-donnee'>
                <h2>Sélectionner un client ou saisir un nouveau client</h2>
                    <div className='selection-base-donnee'>           
                        <select value={selectedClientId || ''} onChange={handleClientSelect} >
                            <option value="">-- Sélectionner un client --</option>
                            {clients.map(client => (
                                <option key={client.id} value={client.id}>
                                    {client.nom} {client.prenom}
                                </option>
                            ))}
                        </select>
                        <button onClick={()=> setSelecNewClient(true)} >Nouveau Client</button>
                    </div>
            </div>}
            {selecNewClient && <div className='selection-new-client'>
                <h2>Saisir les données du nouveau client</h2>
                <form className='form-new-client'>
                    <div>
                        <label>Nom:</label>
                        <input
                            type="text"
                            name="nom"
                            value={form.nom}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div>
                        <label>Prénom:</label>
                        <input
                            type="text"
                            name="prenom"
                            value={form.prenom}
                            onChange={handleInputChange}
                            required
                        />
                    </div>                
                    <div>
                        <label>Téléphone:</label>
                        <input
                            type="tel"
                            name="telephone"
                            value={form.telephone}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <label>Mail:</label>
                        <input
                            type="mail"
                            name="mail"
                            value={form.mail}
                            onChange={handleInputChange}
                        />
                    </div>
                </form>            
            <div className='btn-selection-client'>
                <button onClick={() => handleEnregistre()}>Valider</button>
                <button onClick={() => setSelecNewClient(false)}>Annuler</button>
            </div>
        </div> }



        </div>
    );
}

export default SaisieRdvPersonnel