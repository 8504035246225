import React from 'react'
import { QRCodeCanvas } from 'qrcode.react';
import { useMonContexte } from '../MonContext';
import './qrcode.css';
import useWindowSize from '../UseWindowSize';

const QrCode = () => {
  const size = useWindowSize()

  const {adresseServeurBackEnd, ficheClient, fichePersonnel } = useMonContexte();
  
  const qrValue = JSON.stringify({
    url: `${adresseServeurBackEnd}/upload/${ficheClient.id}`,
    //url: `http://192.168.1.8:5000/upload/${ficheClient.id}`,
    nom: ficheClient.nom,
    prenom: ficheClient.prenom,
  });

  function formatQrCode(){
    let formatQr = 128;
    if (size.height > 751) {
       formatQr = 128;
    } else {
      formatQr = 96;
    }
    return formatQr;
  }
  
    return (
      <div className='qrcode' >
        <h3>QR Code de {ficheClient.prenom}: </h3>
        <QRCodeCanvas value={qrValue} size={formatQrCode()} />        
      </div>
    );
}

export default QrCode