
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'
import { useNavigate } from 'react-router-dom';
import { useMonContexte } from '../MonContext';
import GestionEmployes from './GestionEmployes';
import './ajoutPersonnel.css';


const AjoutPersonnel = ({id}) => {
    const { adresseServeurBackEnd, contenuPageDroite, setContenuPageDroite, setContenuPageCentrale, 
                fichePersonnel, setPageConnexion, fichePersonnelSelect, setFichePersonnelSelect } = useMonContexte();
  

    const [employees, setEmployees] = useState([]);
    const [listeFonction, setListeFonction] = useState([]);
    const [form, setForm] = useState({
        id: null,
        nom: '',
        prenom: '',
        username: '',
        password: '',
        fonction: '',
        couleurEDT: '#FFFFFF', // Ajouter la couleur par défaut (blanc par exemple)
    });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  function choixAjoutModif (id) {
    if (id){
      console.log(fichePersonnelSelect)
      setForm({
        id: fichePersonnelSelect.id,
        nom: fichePersonnelSelect.nom,
        prenom: fichePersonnelSelect.prenom,
        username: fichePersonnelSelect.username,
        password: '', // Champ mot de passe laissé vide pour ne pas le modifier par défaut
        role_id: fichePersonnelSelect.role_id, // Initialiser la fonction actuelle
        couleurEDT: fichePersonnelSelect.couleurEDT || '#FFFFFF' // Conserver la couleur actuelle ou par défaut
    });
    } else {
        setForm({
            id: null,
            nom: '',
            prenom: '',
            username: '',
            password: '',
            role_id:'',
            couleurEDT: '#FFFFFF', // Ajouter la couleur par défaut (blanc par exemple)
        })
    }

  }

  const fetchFonction = async () => {
    try {
      const response = await axios.get(`${adresseServeurBackEnd}/listeFonction`,
      {headers:{ Authorization: `Bearer ${fichePersonnel.token}`}
    });
      if (Array.isArray(response.data)) {
        setListeFonction(response.data);
        console.log('liste fonction: ',response.data)
      } else {
        throw new Error('La réponse de l\'API n\'est pas un tableau');
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
        navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
        setPageConnexion(false)
      } else {
        setError('Erreur lors du chargement des rôles');
        console.error('Erreur lors du chargement des rôles', error);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  
  const fetchEmployees = async () => {
    try {
        const response = await axios.get(`${adresseServeurBackEnd}/listeEmployes`,
        {headers:{ Authorization: `Bearer ${fichePersonnel.token}`}
        });
      if (Array.isArray(response.data)) {
        setEmployees(response.data);
      } else {
        setError('La réponse de l\'API n\'est pas un tableau');
        console.error('La réponse de l\'API n\'est pas un tableau', response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
        navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
        setPageConnexion(false)
      } else {      
        setError('Erreur lors du chargement des employés');
        console.error('Erreur lors du chargement des employés', error);
      }
    }
  };

    

      const handleColorChange = (color) => {
          setForm((prevForm) => ({ ...prevForm, couleurEDT: color.hex }));
        };
    
      const handleSubmit = async (e) => {
        console.log('SubmitForm: ', form)
        e.preventDefault();
        try {
          if (form.id) {
            await axios.put(`${adresseServeurBackEnd}/employes/${form.id}`, form, {
              headers: {
                  Authorization: `Bearer ${fichePersonnel.token}`,
                  'Content-Type': 'application/json'
              }
          });
          } else {
            await axios.post(`${adresseServeurBackEnd}/InsereEmployes`, form, {
              headers: {
                  Authorization: `Bearer ${fichePersonnel.token}`,
                  'Content-Type': 'application/json'
              }
          });
          }
          setForm({ id: null, nom: '', prenom: '', username: '', password: '', 
                    couleurEDT: '#FFFFFF', role_id:'' });
          fetchEmployees();
        } catch (error) {
          setError('Erreur lors de l\'enregistrement de l\'employé');
          console.error('Erreur lors de l\'enregistrement de l\'employé', error);
        }
        setContenuPageCentrale(<GestionEmployes/>);

      };

      useEffect(() => {
        choixAjoutModif(id);
        fetchFonction();
      }, [])

  return (
    <div className='container-ajout-personnel'>
        {id? <h1> Modification de {fichePersonnelSelect.prenom}</h1>:<h1>Ajout d'un nouvel employé</h1>}
       
        <form className="employees-form" onSubmit={handleSubmit}> 
            <div className='bloc-formulaire-selectColor'>
                <div className='formulaire'>
                    <label>Nom</label>
                    <input
                        type="text"
                        name="nom"
                        placeholder="Nom"
                        value={form.nom}
                        onChange={handleChange}
                        required
                    />
                    <label>Prénom</label>
                    <input
                        type="text"
                        name="prenom"
                        placeholder="Prénom"
                        value={form.prenom}
                        onChange={handleChange}
                        required
                    />
                    <label>Username</label>
                    <input
                        type="text"
                        name="username"
                        placeholder="Nom d'utilisateur"
                        value={form.username}
                        onChange={handleChange}
                        required
                    />
                    <label>Password</label>
                    <input
                        type="password"
                        name="password"
                        placeholder="Mot de passe"
                        value={form.password}
                        onChange={handleChange}
                        minLength={6}
                    />
                    <label>Fonction:</label>
                        
                        <select
                        className="selection-fonction"
                        name="role_id"
                        value={form.role_id}
                        onChange={handleChange}
                        required
                        > 
                        <option>
                        Sélectionner une fonction
                        </option>                       
                        {listeFonction.map((role) => (
                            <option key={role.id} value={role.id}>
                            {role.role}
                            </option>
                        ))}
                        </select>
                    
                </div>

                <div className="bloc-color-picker">
                    <h2>Sélectionner une couleur pour l'EDT :</h2>
                    <div className="color-picker">
                    <SketchPicker
                    color={form.couleurEDT}
                    onChangeComplete={handleColorChange}
                    />
                    </div>
                </div>
            </div>
            <div className="btn-ajouter-employe">
                <button className="btn" type="submit">
                    {form.id ? 'Modifier' : 'Ajouter'}
                </button>
                <button className="btn" onClick={() => setContenuPageCentrale(<GestionEmployes/>)}>
                    Annuler
                </button>
            </div>
        </form>
      
    </div>
  )
}

export default AjoutPersonnel

/*
const AjoutPersonnel = ({id}) => {
    const { adresseServeurBackEnd, contenuPageDroite, setContenuPageDroite, setContenuPageCentrale, 
                fichePersonnel, setPageConnexion, fichePersonnelSelect, setFichePersonnelSelect } = useMonContexte();
  

    const [employees, setEmployees] = useState([]);
    const [form, setForm] = useState({
        id: null,
        nom: '',
        prenom: '',
        username: '',
        password: '',
        fonction: '',
        couleurEDT: '#FFFFFF', // Ajouter la couleur par défaut (blanc par exemple)
    });
  const [error, setError] = useState('');
  const [visibleFormAjouter, setVisibleFormAjouter] = useState(false);
  const [visibleAjouterFonction, setVisibleAjouterFonction] = useState(false);
  const [visibleGestionFonction, setVisibleGestionFonction] = useState(false);
  const [visibleListePersonnel, setVisibleListePersonnel] = useState(true);
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();

  function choixAjoutModif (id) {
    if (id){
        setForm(fichePersonnelSelect)
    } else {
        setForm({
            id: null,
            nom: '',
            prenom: '',
            username: '',
            password: '',
            fonction: '',
            couleurEDT: '#FFFFFF', // Ajouter la couleur par défaut (blanc par exemple)
        })
    }

  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  
  const fetchEmployees = async () => {
    try {
        const response = await axios.get(`${adresseServeurBackEnd}/employes`,
        {headers:{ Authorization: `Bearer ${fichePersonnel.token}`}
        });
      if (Array.isArray(response.data)) {
        setEmployees(response.data);
      } else {
        setError('La réponse de l\'API n\'est pas un tableau');
        console.error('La réponse de l\'API n\'est pas un tableau', response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
        navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
        setPageConnexion(false)
      } else {      
        setError('Erreur lors du chargement des employés');
        console.error('Erreur lors du chargement des employés', error);
      }
    }
  };

  

  

    const handleColorChange = (color) => {
        console.log('ChangeColor: ',color)
        setForm((prevForm) => ({ ...prevForm, couleurEDT: color.hex }));
      };
    
      const handleSubmit = async (e) => {
        console.log('SubmitColor: ', form)
        e.preventDefault();
        try {
          if (form.id) {
            await axios.put(`${adresseServeurBackEnd}/employes/${form.id}`, form, {
              headers: {
                  Authorization: `Bearer ${fichePersonnel.token}`,
                  'Content-Type': 'application/json'
              }
          });
          } else {
            await axios.post(`${adresseServeurBackEnd}/employes`, form, {
              headers: {
                  Authorization: `Bearer ${fichePersonnel.token}`,
                  'Content-Type': 'application/json'
              }
          });
          }
          setForm({ id: null, nom: '', prenom: '', username: '', password: '', fonction: '', 
                    couleurEDT: '#FFFFFF' });
          fetchEmployees();
        } catch (error) {
          setError('Erreur lors de l\'enregistrement de l\'employé');
          console.error('Erreur lors de l\'enregistrement de l\'employé', error);
        }
        setContenuPageCentrale(<GestionEmployes/>);

      };

      useEffect(() => {
        choixAjoutModif(id);
      }, [])

  return (
    <div className='container-ajout-personnel'>
        {id? <h1> Modification de {fichePersonnel.prenom}</h1>:<h1>Ajout d'un nouvel employé</h1>}
       
        <form className="employees-form" onSubmit={handleSubmit}> 
            <div className='bloc-formulaire-selectColor'>
                <div className='formulaire'>
                    <label>Nom</label>
                    <input
                        type="text"
                        name="nom"
                        placeholder="Nom"
                        value={form.nom}
                        onChange={handleChange}
                        required
                    />
                    <label>Prénom</label>
                    <input
                        type="text"
                        name="prenom"
                        placeholder="Prénom"
                        value={form.prenom}
                        onChange={handleChange}
                        required
                    />
                    <label>Username</label>
                    <input
                        type="text"
                        name="username"
                        placeholder="Nom d'utilisateur"
                        value={form.username}
                        onChange={handleChange}
                        required
                    />
                    <label>Password</label>
                    <input
                        type="password"
                        name="password"
                        placeholder="Mot de passe"
                        value={form.password}
                        onChange={handleChange}
                        minLength={6}
                    />
                 {   <label>
                        Sélectionner un fonction:
                        <select
                        className="selection-fonction"
                        name="fonction"
                        value={form.fonction}
                        onChange={handleChange}
                        required
                        >
                        <option value="" disabled>
                            Choisir une fonction
                        </option>
                        {roles.map((role) => (
                            <option key={role.id} value={role.role}>
                            {role.role}
                            </option>
                        ))}
                        </select>
                    </label> }
                </div>

                <div className="bloc-color-picker">
                    <h2>Sélectionner une couleur pour l'EDT :</h2>
                    <div className="color-picker">
                    <SketchPicker
                    color={form.couleurEDT}
                    onChangeComplete={handleColorChange}
                    />
                    </div>
                </div>
            </div>
            <div className="btn-ajouter-employe">
                <button className="btn" type="submit">
                    {form.id ? 'Modifier' : 'Ajouter'}
                </button>
                <button className="btn" onClick={() => setContenuPageCentrale(<GestionEmployes/>)}>
                    Annuler
                </button>
            </div>
        </form>
      
    </div>
  )
}

export default AjoutPersonnel
*/