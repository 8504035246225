import React, { useEffect, useState } from 'react'
import useWindowSize from '../UseWindowSize';
import AppWebDesktop from './TypeEcran/AppWebDesktop';
import AppWebPhone from './TypeEcran/AppWebPhone';

const Webnail = () => {  
  
  const size = useWindowSize() 
  const [typeEcran, setTypeEcran] = useState(<AppWebDesktop/>)

  useEffect(() => {      
    if (size.width > 700) {
      setTypeEcran(<AppWebDesktop/>);   
    } else {
      setTypeEcran(<AppWebPhone/>);
    }
  }, [size.width]);



  return (
    <>
      {typeEcran}
    </>
  );
}

export default Webnail