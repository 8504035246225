import React, { useEffect, useState } from 'react';
import './entete.css';
import alyceLogo from '../image/alyce2.png';
import { useMonContexte } from '../MonContext';
import { FaPowerOff } from "react-icons/fa6";
import { BiBell } from "react-icons/bi";
import axios from 'axios';
import BoiteCommunication from './BoiteCommunication';
import CalendrierPersonnel from '../Calendrier/CalendrierPersonnel'
import imgCalendrier from '../image/calendrier-48.png'
import useWindowSize from '../UseWindowSize';
import { useNavigate } from 'react-router-dom';
import GestionClients from '../Client/GestionClients';
import InfoGestionPersonnel from '../Calendrier/InfoGestionPersonnel';


const EnteteUser = () => {
  const size = useWindowSize()

  const {adresseServeurBackEnd, setPageConnexion, fichePersonnel, objectifs, setObjectifs, nbreMessage, encours, 
        setEncours, setContenuPageCentrale,  setContenuPageDroite, fichePersonnelSelect} =  useMonContexte();

  const navigate = useNavigate();

    const [objectifMensuel, setObjectifMensuel] = useState(150);
    const [balance, setBalance] = useState(0);

  const moisOptions = [
    'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
    'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
  ];

  function obtenirAnneeActuels() {  
    const dateActuelle = new Date();
    const annee = dateActuelle.getFullYear().toString();     
    return annee;
  };

  function obtenirMoisActuels() {  
    const dateActuelle = new Date();
    const mois = moisOptions[dateActuelle.getMonth()];    
    return mois;
  };

  function getCurrentMonthInFrench() {
    const now = new Date();
    const year = now.getFullYear();
    const monthIndex = 1 + now.getMonth();
    const mois = monthIndex.toString()
    return `${year}-0${mois}`;
  }

const mois = obtenirMoisActuels();
const annee = obtenirAnneeActuels(); 

  const calculateTotal = (valeurs) => {
    return valeurs.reduce((total, valeur) => total + parseFloat(valeur || 0), 0).toFixed(2);
  };

  const calculeObjectifs = async () => { 
    try{
      const response = await axios.get(`${adresseServeurBackEnd}/objectifsEmploye/${fichePersonnel.id}/${annee}/${mois}`,
      {headers: {Authorization: `Bearer ${fichePersonnel.token}`}
      });
      console.log(response.data)
      setObjectifs(response.data)
      setObjectifMensuel(calculateTotal(response.data.valeurs));
      setBalance(encours - calculateTotal(response.data.valeurs))
    } catch (error) {
        if (error.response && error.response.status === 403) {
          console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
          navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
          setPageConnexion(false)
        }
      }  
  };

  const recuperationEncoursMois = async () => { 
    const anneeMois = getCurrentMonthInFrench();
    try {
      const response = await axios.get(`${adresseServeurBackEnd}/prestationclients/employe/${fichePersonnel.id}/mois/${anneeMois}`,
        {headers: {Authorization: `Bearer ${fichePersonnel.token}`}
        });
        setEncours(response.data.totalPrestations);
    } catch (error) {
        if (error.response && error.response.status === 403) {
          console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
          navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
          setPageConnexion(false)
        }
    }  
 };

  const deconnexion = () => {
    setContenuPageCentrale(<GestionClients/>);
    setPageConnexion(false);
  }

  const colorationBalance = () =>{
    let signe = '-';  
    if (balance >= 0) {
        signe = '+';
    }
    return signe;
  }

  useEffect(() => {
    calculeObjectifs();
   }, [mois, annee,fichePersonnel]);

  useEffect(() => {
   // calculeObjectifs();
   // recuperationEncoursMois();
  }, [objectifs]);

  useEffect(() => {
    //  calculeObjectifs();
  }, [encours]);

  const handleCalendrier = () => {
    setContenuPageCentrale(<CalendrierPersonnel id={fichePersonnel.id}  />)
    setContenuPageDroite(<InfoGestionPersonnel />)
  }

  return (
    <div className='navigation-entete'> 
      <div className='bloc-logo-entete'>
          <img className='logo-alyce' src={alyceLogo} alt='img'/>
      </div>
      <nav className='navbar-entete'> 
         <div className='bloc-ul-entete'>     
          <ul className="nav-menu-entete">
            <li className="nav-item-entete"><span className='personnel-entete'> {fichePersonnel.nom} &nbsp; {fichePersonnel.prenom} </span> </li>
            <li className="nav-item-entete">Objectif {obtenirMoisActuels()} :<span className='objectif-entete'>{objectifs.objectifMensuel} € </span> </li>
            <li className="nav-item-entete">Encours :<span className='color-bleu'>{encours} € </span> </li>
            <li className="nav-item-entete">balance :<span className={balance > 0 ? 'color-vert':'color-rouge'}>{colorationBalance()}{Math.abs(balance)} € </span> </li>
            <li className="nav-item-entete" id='calendrier-entete' onClick={()=>handleCalendrier()}>
              <img id='img-calendar' src={imgCalendrier} alt='calendar'/>
            </li>
          </ul>
        </div> 
      </nav>
      <div className='bloc-btn-entete'>
          <div className='btn-entete'>
              <BiBell className='icon-navigation' onClick={()=>setContenuPageCentrale(<BoiteCommunication /> )} />
              <span className='notification-message'>
                  {nbreMessage}
              </span>
          </div>
          <div className='btn-entete'>
              <FaPowerOff className='icon-navigation' onClick={() => deconnexion()}/>
          </div>     
      </div>
    </div>
  )
}

export default EnteteUser