import React, { useEffect, useState } from 'react'
import './pageCourante.css'
import EnteteUser from '../Entete/EnteteUser'
import { useMonContexte } from '../MonContext'
import MenuFonction from '../PageTemplete/MenuFonction'
import axios from 'axios'
import RecuperationDonnee from '../RecuperationDonnee'
import { Navigate, useNavigate } from 'react-router-dom'

const PageCourante = () => {

  const {adresseServeurBackEnd, fichePersonnel, setNbreMessage, contenuPageCentrale, setPageConnexion, contenuPageDroite,     
        } =  useMonContexte();

  const navigate = useNavigate();
    

  const checkNewMessages = async () => {
    try {
        const response = await axios.get(`${adresseServeurBackEnd}/newMessagesCount/${fichePersonnel.id}`,
          {headers:{ Authorization: `Bearer ${fichePersonnel.token}`}
        });
        setNbreMessage(response.data.newMessagesCount);
    } catch (error) {
        if (error.response && error.response.status === 403) {
          console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
          navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
          setPageConnexion(false)
        } else {
          console.error('Erreur lors de la vérification des nouveaux messages:', error);
    }
  }
  
};
/*
useEffect(() => {
    const interval = setInterval(checkNewMessages, 2000);

    return () => clearInterval(interval);
}, []);    
*/


  return (
    <div>        
        <RecuperationDonnee/>
        <EnteteUser/>
        <div className="bloc-page-courante">
            <div className='section-gauche'>
              <MenuFonction/>
            </div>
            <div className="section-centrale">
              {contenuPageCentrale}
            </div>
            <div className="section-droite">
              {contenuPageDroite}
            </div>
        </div>
    </div>
  )
}

export default PageCourante





/*


// Route pour ajouter une prestation client  
app.post('/prestationclients', async (req, res) => {
  const { clientId, employeId, date, prixTotal, modePaiement,prestations} = req.body;
  const connection = await db.getConnection(); // Obtenir une connexion à partir du pool
  await connection.beginTransaction(); // Démarrer une transaction

try {
// Insérer le prestation
const [resultPrestation] = await connection.query(
'INSERT INTO TablePrestationClients (clientId, employeId, date, prixTotal, modePaiement) VALUES (?, ?, ?, ?, ?)',
[clientId, employeId, date, prixTotal, modePaiement]
);

const prestationClientsId = resultPrestation.insertId;

// Insérer les prestations associées au rendez-vous
const prestationPromises = prestations.map(prestationId => {
return connection.query(
  'INSERT INTO TableDetailPrestationClients (prestationClientsId, prestationId) VALUES (?, ?)',
  [prestationClientsId, prestationId]
);
});

await Promise.all(prestationPromises); // Attendre que toutes les insertions soient terminées

await connection.commit(); // Valider la transaction

res.status(201).json({ id: prestationClientsId, clientId, employeId, date, prixTotal, modePaiement, prestations });
} catch (error) {
await connection.rollback(); // Annuler la transaction en cas d'erreur
console.error('Erreur lors de la création du rendez-vous:', error);
res.status(500).json({ message: 'Erreur serveur' });
} finally {
connection.release(); // Libérer la connexion
}
});


*/