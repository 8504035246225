import React, { useState } from 'react';
import './Carousel.css'; // Importez le fichier CSS pour les styles
import { useMonContexte } from '../MonContext';

const Carousel = ({ images }) => {
    const {adresseServeurBackEnd } =  useMonContexte();

  const [currentIndex, setCurrentIndex] = useState(0); // État pour l'image affichée actuellement

  // Fonction pour passer à l'image suivante
  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Fonction pour revenir à l'image précédente
  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="carousel">
      <div className="carousel-wrapper">
        <button className="carousel-button prev-button" onClick={prevImage}>
          ❮
        </button>
        <img src={`${adresseServeurBackEnd}/${images[currentIndex].file_path}`} alt={`Slide ${currentIndex}`} className="carousel-image" />

        <button className="carousel-button next-button" onClick={nextImage}>
          ❯
        </button>
      </div>
      <div className="carousel-indicators">
        {images.map((_, index) => (
          <span
            key={index}
            className={`indicator ${index === currentIndex ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
