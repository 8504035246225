
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './saisieRdv.css';
import { useMonContexte } from '../MonContext';
import 'moment/locale/fr';
import Calendrier from '../Calendrier/Calendrier';
import FicheClient from '../Client/FicheClient';
import { useNavigate } from 'react-router-dom';

const PriseRDVClient = () => {
    const { adresseServeurBackEnd, ficheClient, setContenuPageCentrale, fichePersonnel, setPageConnexion } = useMonContexte();
  
    const [choixPresta, setChoixPresta] = useState([]);
    const [prixTotal, setPrixTotal] = useState(0);
    const [dureeTotalePresta, setDureeTotalePresta] = useState(0);
    const [prestations, setPrestations] = useState([]);
    const [prestaId, setPrestaId] = useState([]);
    const [employesDisponibles, setEmployesDisponibles] = useState([]);
    const [formData, setFormData] = useState({ dateHeure: '', employeId: '', prestations: [] });
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const fetchPrestations = async () => {
        try {
            const response = await axios.get(`${adresseServeurBackEnd}/prestations`, {
                headers: { Authorization: `Bearer ${fichePersonnel.token}` }
            });
            setPrestations(response.data);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
                navigate('/gestion');
                setPageConnexion(false);
            } else {
                console.error('Erreur lors de la récupération des prestations:', error);
            }
        }
    };

    const fetchDisponibiliteEmploye = async () => {
        try {
            const response = await axios.get(`${adresseServeurBackEnd}/disponibilite`, {
                headers: { Authorization: `Bearer ${fichePersonnel.token}` },
                params: {
                    dateHeure: formData.dateHeure,
                    duree: dureeTotalePresta
                }
            });
            setEmployesDisponibles(response.data);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
                navigate('/gestion');
                setPageConnexion(false);
            } else {
                console.error('Erreur lors de la récupération des disponibilites:', error);
            }
        }
    };

    useEffect(() => {
        fetchPrestations();
        console.log(ficheClient)
    }, []);

    useEffect(() => {
        if (dureeTotalePresta > 0 && formData.dateHeure) {
            fetchDisponibiliteEmploye();
        }
    }, [dureeTotalePresta, formData.dateHeure]);

  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setError('');
      if (name === 'dateHeure') {
        const dateHeure = new Date(value);
        setFormData({ ...formData, [name]: dateHeure });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    };
  
    const handleClick = (prestation) => {
      setError('');
      const prestationData = {
        id: prestation.id,
        nom: prestation.nom,
        duree: prestation.duree,
        prix: prestation.prix
      };
      const nouvelleDureeTotale = dureeTotalePresta + prestation.duree;
      setDureeTotalePresta(nouvelleDureeTotale);
      setChoixPresta([...choixPresta, prestationData]);
      setPrixTotal(prixTotal + parseFloat(prestation.prix));
      const nouveauxIdsPrestations = [...prestaId, prestation.id];
      setPrestaId(nouveauxIdsPrestations);
      const dateHeure = new Date(formData.dateHeure);
      const heureFin = new Date(dateHeure.getTime() + nouvelleDureeTotale * 60000);
      setFormData({
        ...formData,
        heureFin: formatDateTimeLocal(heureFin),
        prestations: nouveauxIdsPrestations,
        status: 'Scheduled'
      })
    }
  
    useEffect(() => {
      const dateHeure = new Date(formData.dateHeure);
      const heureFin = new Date(dateHeure.getTime() + dureeTotalePresta * 60000);
      setFormData({
        ...formData,
        heureFin: formatDateTimeLocal(heureFin),
        prestations: prestaId
      });
    }, [prestaId, dureeTotalePresta]);
  
    const handleClickClear = () => {
      setError('');
      setDureeTotalePresta(0);
      setPrixTotal(0);
      setChoixPresta([]);
      setPrestaId([]);
    };
  
    const handleAnnule = () => {
      setContenuPageCentrale(<FicheClient />);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('ficheClient.id= ', ficheClient.id)
        try {
          const formattedData = {
              ...formData,
              clientId: ficheClient.id,
              dateHeure: new Date(formData.dateHeure).toISOString().slice(0, 19).replace('T', ' '),
              heureFin: new Date(formData.heureFin).toISOString().slice(0, 19).replace('T', ' '),
          };
        console.log(formattedData)
        
            const response = await axios.post(`${adresseServeurBackEnd}/rendezvous`, formattedData, {
                headers: {
                    Authorization: `Bearer ${fichePersonnel.token}`,
                    'Content-Type': 'application/json'
                }
            });
            console.log('Rendez-vous créé:', response.data);
            setContenuPageCentrale(<Calendrier />);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
                navigate('/gestion');
                setPageConnexion(false);
            } else {
                console.error('Erreur lors de la création du rendez-vous:', error);
                setError('Erreur lors de la création du rendez-vous');
            }
        }
    };

    const formatDateTimeLocal = (date) => {
        const d = new Date(date);
        return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}T${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}`;
    };

    return (
        <div className="saisie-rdv-container">
            <h2>Ajouter un Rendez-vous pour</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            
            <form onSubmit={handleSubmit}>
                <div className='bloc-identité-rdv'>
                    <div className="client-info">
                        <strong>{` ${ficheClient.prenom} ${ficheClient.nom}`}</strong>
                    </div>         
                    <div className="client-info">
                        Tel:<strong> {ficheClient.telephone}</strong>
                    </div> 
                    <div className="client-info">
                        mail:<strong> {ficheClient.mail}</strong>
                    </div>       
                </div>

                <label>
                    Date et Heure:
                    <input
                        type="datetime-local"
                        name="dateHeure"
                        value={formatDateTimeLocal(formData.dateHeure)}
                        onChange={handleInputChange}
                        required
                    />
                </label>
                
                <div className='bloc-prestation'>
                    <p>Choisissez vos prestations:</p>
                    <div className='label-presta-choix'>
                        <label className='label-presta'>              
                            <select name="prestations" multiple value={prestations.map(p => p.id)} readOnly>
                                {prestations.map((prestation) => (
                                    <option key={prestation.id} value={prestation.id} onClick={() => handleClick(prestation)}>
                                        {prestation.nom}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <div className='choix-presta'>
                            <div className='choix-ul-li'>
                                <ul>
                                    {choixPresta.map((choix, index) => (
                                        <li key={index}>
                                            {choix.nom} {choix.duree} min
                                        </li>
                                    ))}
                                </ul>  
                            </div>
                        </div> 
                        <div className='choix-totaux'>
                            Prix total: {prixTotal}€ <br/> Durée total: {dureeTotalePresta} min
                        </div>
                    </div>
                    <div className='btn-choix-presta'>
                        <button type="button" onClick={handleClickClear}>Clear</button>
                    </div>
                </div>

                <label>
                    Heure de Fin:
                    <input
                        type="datetime-local"
                        name="heureFin"
                        value={formatDateTimeLocal(new Date(new Date(formData.dateHeure).getTime() + dureeTotalePresta * 60000))}
                        readOnly
                    />
                </label>
                <label className='label-select-employe'>
                    Employé:
                    <select
                        name="employeId"
                        value={formData.employeId}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Sélectionner un employé</option>
                        {employesDisponibles.map((employe) => (
                            <option key={employe.id} value={employe.id}>
                                {employe.prenom}
                            </option>
                        ))}
                    </select>
                </label>
                <div className="btn-form-rdv">
                    <button type="submit">Enregistrer</button>
                    <button type="button" onClick={handleAnnule}>Annuler</button>
                </div>
            </form>
        </div>
    );
};

export default PriseRDVClient;




/*

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './saisieRdv.css';
import { useMonContexte } from '../MonContext';
import 'moment/locale/fr';
import Calendrier from '../Calendrier/Calendrier';
import FicheClient from '../Client/FicheClient';
import { useNavigate } from 'react-router-dom';

const PriseRDVClient = () => {
    const { adresseServeurBackEnd, ficheClient, setContenuPageCentrale, fichePersonnel, setPageConnexion } = useMonContexte();
  
    const [formData, setFormData] = useState( {
      nomPrenom: `${ficheClient.nom}-${ficheClient.prenom}`,
      employeId: '',
      dateHeure: '',
      telephone: ficheClient.telephone,
      prestations: [],
      heureFin: '',
      status: 'Scheduled',
      isClient: 1
    });
  
    const [choixPresta, setChoixPresta] = useState([]);
    const [prixTotal, setPrixTotal] = useState(0);
    const [dureeTotalePresta, setDureeTotalePresta] = useState(0);
    const [prestations, setPrestations] = useState([]);
    const [prestaId, setPrestaId] = useState([]);
    const [employesDisponibles, setEmployesDisponibles] = useState([]);
    const [error, setError] = useState('');
    const navigate =useNavigate();
  
    const fetchPrestations = async () => {
      try {
        const response = await axios.get(`${adresseServeurBackEnd}/prestations`, {
          headers: { Authorization: `Bearer ${fichePersonnel.token}` }
      });
        setPrestations(response.data);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
          navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
          setPageConnexion(false)
        } else {
          console.error('Erreur lors de la récupération des prestations:', error);
        }
      }
    };
  
    const fetchDisponibiliteEmploye = async () => {
      try {
        const response = await axios.get(`${adresseServeurBackEnd}/disponibilite`, {
          headers: { Authorization: `Bearer ${fichePersonnel.token}` },
          params: {
              dateHeure: formData.dateHeure,
              duree: dureeTotalePresta
          }
        });
        setEmployesDisponibles(response.data);
        console.log('Dispo: ', response.data)
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
          navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
          setPageConnexion(false)
        } else {
          console.error('Erreur lors de la récupération des disponibilites:', error);
        }
      }
    };
  
    useEffect(() => {
      console.log(formData)
      fetchPrestations();
    }, []);
  
    useEffect(() => {
      fetchDisponibiliteEmploye();
    }, [dureeTotalePresta, formData.dateHeure]);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setError('');
      if (name === 'dateHeure') {
        const dateHeure = new Date(value);
        setFormData({ ...formData, [name]: dateHeure });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    };
  
    const handleClick = (prestation) => {
      setError('');
      const prestationData = {
        id: prestation.id,
        nom: prestation.nom,
        duree: prestation.duree,
        prix: prestation.prix
      };
      const nouvelleDureeTotale = dureeTotalePresta + prestation.duree;
      setDureeTotalePresta(nouvelleDureeTotale);
      setChoixPresta([...choixPresta, prestationData]);
      setPrixTotal(prixTotal + parseFloat(prestation.prix));
      const nouveauxIdsPrestations = [...prestaId, prestation.id];
      setPrestaId(nouveauxIdsPrestations);
      const dateHeure = new Date(formData.dateHeure);
      const heureFin = new Date(dateHeure.getTime() + nouvelleDureeTotale * 60000);
      setFormData({
        ...formData,
        heureFin: formatDateTimeLocal(heureFin),
        prestations: nouveauxIdsPrestations,
        status: 'Scheduled'
      })
    }
  
    useEffect(() => {
      const dateHeure = new Date(formData.dateHeure);
      const heureFin = new Date(dateHeure.getTime() + dureeTotalePresta * 60000);
      setFormData({
        ...formData,
        heureFin: formatDateTimeLocal(heureFin),
        prestations: prestaId
      });
    }, [prestaId, dureeTotalePresta]);
  
    const handleClickClear = () => {
      setError('');
      setDureeTotalePresta(0);
      setPrixTotal(0);
      setChoixPresta([]);
      setPrestaId([]);
    };
  
    const handleAnnule = () => {
      setContenuPageCentrale(<FicheClient />);
    }
  
   
    const handleSubmit = async (e) => {
      e.preventDefault();
      console.log(formData);
  
      try {
        const formattedData = {
          ...formData,
          dateHeure: new Date(formData.dateHeure).toISOString().slice(0, 19).replace('T', ' '),
          heureFin: new Date(formData.heureFin).toISOString().slice(0, 19).replace('T', ' '),
          isClient: 1
        };
        console.log(formattedData.isClient);
        const response = await axios.post(`${adresseServeurBackEnd}/rendezvous`, formattedData, {
          headers: {
              Authorization: `Bearer ${fichePersonnel.token}`,
              'Content-Type': 'application/json'
          }
      });
        console.log('Rendez-vous créé:', response.data);
        setContenuPageCentrale(<Calendrier />);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
          navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
          setPageConnexion(false)
        } else {
          console.error('Erreur lors de la création du rendez-vous:', error);
          setError('Erreur lors de la création du rendez-vous');
        } 
      }
    };
  
    const formatDateTimeLocal = (date) => {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate()).padStart(2, '0');
      const hours = String(d.getHours()).padStart(2, '0');
      const minutes = String(d.getMinutes()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
  
    return (
      <div className="saisie-rdv-container">
        <h2>Ajouter un Rendez-vous pour</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        
        <form onSubmit={handleSubmit}>
        <div className='bloc-identité-rdv'>
          <div className="client-info">
             <strong>{` ${ficheClient.prenom} ${ficheClient.nom}`}</strong>
          </div>         
          <div className="client-info">
            Tel:<strong> {ficheClient.telephone}</strong>
          </div> 
          <div className="client-info">
            mail:<strong> {ficheClient.mail}</strong>
          </div>       
        </div>

          <label>
            Date et Heure:
            <input
              type="datetime-local"
              name="dateHeure"
              value={formatDateTimeLocal(new Date(formData.dateHeure))}
              onChange={handleInputChange}
              required
            />
          </label>
          
          <div className='bloc-prestation'>
            <p>Choisissez vos prestations:</p>
            <div className='label-presta-choix'>
              <label className='label-presta'>              
                <select name="prestations" multiple value={formData.prestations} readOnly>
                  {prestations.map((prestation) => (
                    <option key={prestation.id} value={prestation.id} onClick={() => handleClick(prestation)}>
                      {prestation.nom}
                    </option>
                  ))}
                </select>
              </label>
              <div className='choix-presta'>
                <div className='choix-ul-li'>
                  <ul>
                    {choixPresta.map((choix, index) => (
                      <li key={index}>
                        {choix.nom} {choix.duree} min
                      </li>
                    ))}
                  </ul>  
                </div>
              </div> 
                 
            <div className='choix-totaux'>
              Prix total: {prixTotal}€ <br/> Durée total: {dureeTotalePresta} min
            </div>
             </div>
            <div className='btn-choix-presta'>
              <button type="button" onClick={handleClickClear}>Clear</button>
            </div>
          </div>

          <label>
            Heure de Fin:
            <input
              type="datetime-local"
              name="heureFin"
              value={formatDateTimeLocal(new Date(formData.dateHeure ? new Date(formData.dateHeure).getTime() + dureeTotalePresta * 60000 : new Date().getTime()))}
              readOnly
            />
          </label>
          <label className='label-select-employe'>
            Employé:
            <select
              name="employeId"
              value={formData.employeId}
              onChange={handleInputChange}
              required
            >
              <option value="">Sélectionner un employé</option>
              {employesDisponibles.map((employe) => (
                <option key={employe.id} value={employe.id}>
                  {employe.prenom}
                </option>
              ))}
            </select>
          </label>
          <div className="btn-form-rdv">
            <button type="submit">Enregistrer</button>
            <button type="button" onClick={handleAnnule}>Annuler</button>
          </div>
        </form>
      </div>
    );
  };
  
export default PriseRDVClient

*/