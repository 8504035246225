import React, { useEffect, useState } from 'react'
import { useMonContexte } from '../MonContext';
import './ficheClient.css'
import axios from 'axios';
import AffichageClientSelectionnee from '../PageTemplete/AffichageClientSelectionnee';
import AfficheMemo from '../Memo/AfficheMemo';
import { useNavigate } from 'react-router-dom';
import RecupHistorique from '../Historique/RecupHistorique';
import Depense from '../Depense/Depense';

const FicheClient = () => {

    const {adresseServeurBackEnd, ficheClient, fichePersonnel, setContenuPageDroite, setBasculeRDVClient, 
            setPageConnexion} =  useMonContexte();

    const [historiqueRdv, SetHistoriqueRdv] = useState([])
    const [activeTab, setActiveTab] = useState(1); 
    const navigate = useNavigate();

    const handleTabClick = (tabIndex) => {
      setActiveTab(tabIndex);
    };
    
  const fetchRdv = async () => {
    try {
      const response = await axios.get(`${adresseServeurBackEnd}/rendezvous/${ficheClient.id}`,
        {headers: {Authorization: `Bearer ${fichePersonnel.token}`}
      });
      SetHistoriqueRdv(response.data);
      console.log(response.data)
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
        navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
        setPageConnexion(false)
      } else {
      console.error('Erreur lors de la récupération des employés:', error);
    }
   }
  };

  useEffect(() => {
    fetchRdv();
    setBasculeRDVClient(false);
    setContenuPageDroite(<AffichageClientSelectionnee ficheClient={ficheClient} />)
  }, [adresseServeurBackEnd]);


  return (
    <div className='bloc-fiche-cliente'>
        <div className='bloc-fiche-gauche'>
              <div className="client-ident"> 
                          <h1>Fiche de {ficheClient.prenom}</h1>
                            <p><strong>Nom:</strong> {ficheClient.nom}</p>
                            <p><strong>Prénom:</strong> {ficheClient.prenom}</p>
                            <p><strong>Date de Naissance:</strong> {new Date(ficheClient.dateNaissance).toLocaleDateString('fr-FR')}</p>
                            <p><strong>Téléphone:</strong> {ficheClient.telephone}</p>
                            <p><strong>Adresse:</strong> {ficheClient.adresse}</p>
                            <p><strong>Code Postal:</strong> {ficheClient.codePostal}</p>
                            <p><strong>Ville:</strong> {ficheClient.ville}</p>
                            <p><strong>Email:</strong> {ficheClient.mail}</p> 
              </div>               
              <div className='client-depense'>
                  <h1>Les dépenses de {ficheClient.prenom}</h1>
                  <Depense />                  
              </div>
        </div>

        <div className='bloc-fiche-droite'>
            <div className="tabs-container">
                {/* En-tête des onglets */}
                <div className="tabs-header">
                    <button style={{ visibility: activeTab === 1 ?  'hidden':'visible'}} 
                          className={`tab-button ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)} >
                        Memo de {ficheClient.prenom}
                    </button>
                    <button style={{ visibility: activeTab === 2 ?  'hidden':'visible'}} 
                          className={`tab-button ${activeTab === 2 ? 'active' : ''}`} onClick={() => handleTabClick(2)}>
                        Historique des RDV de {ficheClient.prenom} 
                    </button>
                </div>
                              {/* Contenu des onglets */}
                <div className="tabs-content">
                      {activeTab === 1 && (
                        <div className='client-memo-histo'>
                        <h1>Memo de {ficheClient.prenom}</h1>
                          <AfficheMemo/>
                      </div>
                      )}
                      {activeTab === 2 && (
                        <div className='client-memo-histo'>
                          <h1>Historique des RDV de {ficheClient.prenom} </h1>
                                <RecupHistorique/>
                        </div>
                      )}
                </div>
            </div>
    </div>

    </div>
  );
}

export default FicheClient


/*

import React, { useEffect, useState } from 'react'
import { useMonContexte } from '../MonContext';
import './ficheClient.css'
import axios from 'axios';
import AffichageClientSelectionnee from '../PageTemplete/AffichageClientSelectionnee';
import AfficheMemo from '../Memo/AfficheMemo';
import { useNavigate } from 'react-router-dom';

const FicheClient = () => {

    const {adresseServeurBackEnd, ficheClient, fichePersonnel, setContenuPageDroite, setBasculeRDVClient, 
            setPageConnexion} =  useMonContexte();

    const [historiqueRdv, SetHistoriqueRdv] = useState([])

    const navigate = useNavigate();
    
  const fetchRdv = async () => {
    try {
      const response = await axios.get(`${adresseServeurBackEnd}/rendezvous/${ficheClient.id}`,
        {headers: {Authorization: `Bearer ${fichePersonnel.token}`}
      });
      SetHistoriqueRdv(response.data);
      console.log(response.data)
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
        navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
        setPageConnexion(false)
      } else {
      console.error('Erreur lors de la récupération des employés:', error);
    }
   }
  };

  useEffect(() => {
    fetchRdv();
    setBasculeRDVClient(false);
    setContenuPageDroite(<AffichageClientSelectionnee ficheClient={ficheClient} />)
  }, [adresseServeurBackEnd]);


  return (
    <div className='bloc-fiche-cliente'>
        <div className="client-profile-ident">
              <div className='contenu-fiche-client'>
                      <h1>Fiche de {ficheClient.prenom}</h1>
                        <p><strong>Nom:</strong> {ficheClient.nom}</p>
                        <p><strong>Prénom:</strong> {ficheClient.prenom}</p>
                        <p><strong>Date de Naissance:</strong> {new Date(ficheClient.dateNaissance).toLocaleDateString('fr-FR')}</p>
                        <p><strong>Téléphone:</strong> {ficheClient.telephone}</p>
                        <p><strong>Adresse:</strong> {ficheClient.adresse}</p>
                        <p><strong>Code Postal:</strong> {ficheClient.codePostal}</p>
                        <p><strong>Ville:</strong> {ficheClient.ville}</p>
                        <p><strong>Email:</strong> {ficheClient.mail}</p>  
              </div>                                          
        </div>

        <div className='client-profile'>
            <h1>Historique des RDV de {ficheClient.prenom} </h1>
            <div className='bloc-client-details'>
                    <div className="client-details-historique">
                        <ul>
                            {historiqueRdv.map((rdv, index)=> (
                                <li key={index}>{new Date(rdv.dateHeure).toLocaleDateString('fr-FR')}  à  &nbsp; 
                                {new Date(rdv.dateHeure).toLocaleTimeString('fr-FR')} avec {rdv.employePrenom
                                }</li>
                            ))}
                        </ul>
                    </div>
            </div>
        </div>
        <div className='client-profile'>
          <h1>Memo de {ficheClient.prenom}</h1>
            <AfficheMemo/>
        </div>
        <div className='client-profile'>
            <h1>Les photos de {ficheClient.prenom}</h1>
        </div>
    </div>
  );
}

export default FicheClient


*/