import React from 'react'
import alyce from '../image/alyce2.png'
import './header.css'

const Header = () => {
  return (
    <div className='bloc-header-connexion'>
            <img src={alyce} alt='img1' />
    </div>  
  )
}

export default Header