import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './gestionPrestations.css';
import { useMonContexte } from '../MonContext';
import { useNavigate } from 'react-router-dom';
import InfoPrestationVide from './InfoPrestationVide';
import InfoPrestation from './InfoPrestation';
import SaisirPrestation from './SaisirPrestation';
import RestaurePresta from './RestaurePresta';

const GestionPrestations = () => {

  const {adresseServeurBackEnd, fichePersonnel, setPageConnexion, setContenuPageDroite, fichePrestaSelect, 
              setContenuPageCentrale, setFichePrestaSelect} =  useMonContexte();

    const [prestations, setPrestations] = useState([]);
    const navigate = useNavigate();
    const [error, setError] = useState('');
  
    useEffect(() => {
      fetchPrestations();
      setContenuPageDroite(<InfoPrestationVide />)
    }, []);
  
    const fetchPrestations = async () => {
      try {
        const response = await axios.get(`${adresseServeurBackEnd}/prestations`,
        {headers:{ Authorization: `Bearer ${fichePersonnel.token}`}
        });
        if (Array.isArray(response.data)) {
          setPrestations(response.data);
        } else {
          setError('La réponse de l\'API n\'est pas un tableau');
          console.error('La réponse de l\'API n\'est pas un tableau', response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
          navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
          setPageConnexion(false)
        } else {
          setError('Erreur lors du chargement des prestations');
          console.error('Erreur lors du chargement des prestations', error);
        }
      }
    };
  
    const handleSelectPrestations =  (prestation) => { 
      setFichePrestaSelect(prestation)
      setContenuPageDroite(<InfoPrestation />)
    }
  
    return (
      <div className="prestations-manager">
          <h1>Gestion des Prestations</h1>
            {error && <p className="error">{error}</p>}
            <div className='btn-creation-presta'>
                <button onClick={()=>setContenuPageCentrale(<SaisirPrestation/>)}> Créer une Prestation</button>
                <button onClick={()=>setContenuPageCentrale(<RestaurePresta/>)} >Rétablir une Prestation</button>
            </div>
            
          <div className='container-prestations-table'>
            <table className="prestations-table">
              <thead>
                  <tr>
                  <th>Nom</th>
                  <th>Description</th>
                  <th>Durée </th>
                  <th>Prix </th>
                  <th>Actions</th>
                  </tr>
              </thead>
              <tbody>
                  {prestations.map((prestation) => (
                  <tr key={prestation.id}>
                      <td>{prestation.nom}</td>
                      <td>{prestation.description}</td>
                      <td>{prestation.duree} min</td>
                      <td>{prestation.prix} €</td>
                      <td className="action-button">
                          <button  onClick={() => handleSelectPrestations(prestation)}>Sélection</button>
                      </td>
                  </tr>
                  ))}
              </tbody>
            </table>
          </div>
      </div>
    );
}

export default GestionPrestations
