
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './gestionClient.css';
import { useMonContexte } from '../MonContext';
import FicheClient from './FicheClient';
import { useNavigate } from 'react-router-dom';
import ModifAjoutClient from './ModifAjoutClient';
import AfficheClientVide from './AfficheClientVide';
import RestaurerFicheClient from './RestaurerFicheClient';

const GestionClients = () => {

  const {adresseServeurBackEnd, fichePersonnel, setFicheClient, setContenuPageDroite, setContenuPageCentrale,
          setPageConnexion} =  useMonContexte();

    const [clients, setClients] = useState([]);
    const [form, setForm] = useState({
      id: null,
      nom: '',
      prenom: '',
      dateNaissance: '',
      telephone: '',
      adresse: '',
      codePostal: '',
      ville: ''
    });
    const [error, setError] = useState('');
    const navigate = useNavigate();

    
  
    useEffect(() => {
      fetchClients();    
      setContenuPageDroite(<AfficheClientVide/>);
    }, []);
  
    const fetchClients = async () => {
      try {
        const response = await axios.get(`${adresseServeurBackEnd}/clients`,
          {headers:{ Authorization: `Bearer ${fichePersonnel.token}`}
        });
        if (Array.isArray(response.data)) {
          setClients(response.data);
        } else {
          setError('La réponse de l\'API n\'est pas un tableau');
          console.error('La réponse de l\'API n\'est pas un tableau', response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
          navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
          setPageConnexion(false)
        } else {
          setError('Erreur lors du chargement des clients');
          console.error('Erreur lors du chargement des clients', error);
        }
      }
    };
  

    const handleSelectionFicheClient = (client) => {
      setFicheClient(client);
      setContenuPageCentrale(<FicheClient/>)
    }
  
    return (
      <div className="clients-manager">
        <h1>Gestion des Clients</h1>
        {error && <p className="error">{error}</p>}
        <div className='btn-gerer-client'>
                <button onClick={()=>setContenuPageCentrale(<ModifAjoutClient /> )}>
                    Ajouter un client</button>
                <button onClick={()=>setContenuPageCentrale(<RestaurerFicheClient/> )}>
                    Restaurer une Fiche</button>
        </div>
        

      <div className='table-container-client'>
        <table className="clients-table">
          <thead>
            <tr>
              <th>Nom</th>
              <th>Prénom</th>
              <th>Date de Naissance</th>
              <th>Téléphone</th>
              <th>Adresse</th>
              <th>Code Postal</th>
              <th>Ville</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {clients
              .sort((a, b) => a.nom.localeCompare(b.nom))
              .map((client) => (
                <tr key={client.id}>
                  <td>{client.nom}</td>
                  <td>{client.prenom}</td>
                  <td>{new Date(client.dateNaissance).toLocaleDateString('fr-FR')}</td>
                  <td>{client.telephone}</td>
                  <td>{client.adresse}</td>
                  <td>{client.codePostal}</td>
                  <td>{client.ville}</td>
                  <td>{client.mail}</td>
                  <td>
                    <div className='btn-table-client'>
                    <button onClick={() => handleSelectionFicheClient(client)}>Sélection</button>
                    </div>
                  </td>
                </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>
    );
}

export default GestionClients