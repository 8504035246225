
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useMonContexte } from '../MonContext';
import { useNavigate } from 'react-router-dom';
import GestionClients from '../Client/GestionClients';
import './selectClientRdv.css'
import AffichageClientSelectionnee from '../PageTemplete/AffichageClientSelectionnee';

const SelectBaseDonnee = ({fonctionPriseRdv, fonctionSaisieClient,}) => {
    const { adresseServeurBackEnd, fichePersonnel, setFicheClient, ficheClient, setPageConnexion, setContenuPageCentrale, 
            setContenuPageDroite } = useMonContexte();

    
    
    const [error, setError] = useState('');
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [clients, setClients] = useState([]);
    const [form, setForm] = useState({
        id: null,
        nom: '',
        prenom: '',
        dateNaissance: '',
        telephone: '',
        adresse: '',
        codePostal: '',
        ville: '',
        mail:''
    });

    const navigate = useNavigate();

    const fetchClients = async () => {
        try {
            const response = await axios.get(`${adresseServeurBackEnd}/clients`, {
                headers: { Authorization: `Bearer ${fichePersonnel.token}` }
            });
            if (Array.isArray(response.data)) {
                setClients(response.data);
                console.log(response.data)
            } else {
                console.error("La réponse de l'API n'est pas un tableau", response.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
                navigate('/gestion');
                setPageConnexion(false)
            } else {
                console.error('Erreur lors du chargement des clients', error);
            }
        }
    };
    

    const handleClientSelect = (event) => {
        const clientId = event.target.value;
        setSelectedClientId(clientId);

        // Si un client est sélectionné, remplir le formulaire avec ses informations
        const client = clients.find(c => c.id === parseInt(clientId, 10));
        if (client) {
            setFicheClient(client)
        }         
        fonctionPriseRdv();
        setContenuPageDroite(<AffichageClientSelectionnee/>)
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setForm(prevForm => ({
            ...prevForm,
            [name]: value
        }));
    };

    const handleAnnule = () => {
        setContenuPageCentrale(<GestionClients/>)
    }

    const handleEnregistre = async() => {
        const formWithDate = {
            ...form,
            dateNaissance: form.dateNaissance.trim() !== '' ? form.dateNaissance : new Date().toISOString().split('T')[0] // Utilise la date du jour si la date de naissance est vide
        };  
        if (formWithDate.id === null){
            try {
                const response = await axios.post(`${adresseServeurBackEnd}/clients`, formWithDate,{
                    headers: {
                        Authorization: `Bearer ${fichePersonnel.token}`,
                        'Content-Type': 'application/json'
                    }
                }); 
                if (response.data && response.data.id) {
                    const nouveauClientId = response.data.id;
    
                    // Mettre à jour le formulaire avec l'ID du nouveau client
                    setForm(prevForm => ({
                        ...prevForm,
                        id: nouveauClientId
                    })); 
                }  
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
                    navigate('/gestion');
                    setPageConnexion(false)
                } else {
                    setError('Erreur lors du chargement des clients');
                    console.error('Erreur lors du chargement des clients', error);
                }
            }
        }         
        console.log(form);
        setFicheClient(form)
        setContenuPageDroite(<AffichageClientSelectionnee/>)
        fonctionPriseRdv();
    }

    
    useEffect(() => {
        fetchClients();
    }, []);

    return (

        <div className='saisie-rdv-container '>
          <h2>Prendre un Rendez-vous</h2>
          <h5>Sélectionner un client ou saisir un nouveau client</h5>
            <div className='selection-base-donnee'>           
                <select value={selectedClientId || ''} onChange={handleClientSelect} >
                    <option value="">-- Sélectionner un client --</option>
                    {clients.map(client => (
                        <option key={client.id} value={client.id}>
                            {client.nom} {client.prenom}
                        </option>
                    ))}
                </select>
                <button onClick={()=> fonctionSaisieClient()} >Nouveau Client</button>
            </div>
        </div>
    );
}

export default SelectBaseDonnee