
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useMonContexte } from '../MonContext';
import './gestionObjectifs.css';
import { useNavigate } from 'react-router-dom';
import SaisieObjectifs from './SaisieObjectifs';
import InfoObjectifVide from './InfoObjectifVide';

const GestionObjectifs = () => {
  const { adresseServeurBackEnd, fichePersonnel, setPageConnexion,  setContenuPageDroite, } = useMonContexte();
  const [error, setError] = useState('');
  const [infoGestionObjectif, setInfoGestionObjectif] = useState('');
  const [objectifInstitut, setObjectifInstitut] = useState(0.0); // Ici on initialise objectifInstitut
  const [objectif, setObjectif] = useState([]);
  const [mappageSemaine, setMappageSemaine] = useState([]);

  const navigate = useNavigate();

  const moisOptions = [
    'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
    'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
  ];
  
  function obtenirMoisActuels() {  
    const dateActuelle = new Date();
    const mois = moisOptions[dateActuelle.getMonth()];
    return mois;
  };

  function obtenirAnneeActuels() {  
    const dateActuelle = new Date();
    const annee = dateActuelle.getFullYear().toString();
    return annee;
  };

  const [mois, setMois] = useState(obtenirMoisActuels());
  const [annee, setAnnee] = useState(obtenirAnneeActuels());



// Fonction pour calculer le nombre de jours entre deux dates
function calculerNombreJours(dateDebut, dateFin) {
  const unJour = 1000 * 60 * 60 * 24; // Nombre de millisecondes dans un jour
  const difference = dateFin - dateDebut;
  return Math.floor(difference / unJour) + 1;
}

  function decouperMoisEnSemaines(annee, mois) {
// Conversion du nom du mois en un index de mois (de 0 à 11)
    const moisMapping = {
      "janvier": 0,
      "février": 1,
      "mars": 2,
      "avril": 3,
      "mai": 4,
      "juin": 5,
      "juillet": 6,
      "août": 7,
      "septembre": 8,
      "octobre": 9,
      "novembre": 10,
      "décembre": 11
    };  

    const moisIndex = moisMapping[mois.toLowerCase()];
    if (moisIndex === undefined) {
      console.error("Mois invalide:", mois);
      return [];
    }
  
    let semaines = [];
    let dateDebut = new Date(annee, moisIndex, 1);
    let dernierJourMois = new Date(annee, moisIndex + 1, 0);
    let jour1DuMois = new Date(dateDebut);
    let dateDebutSemaine1 = new Date(dateDebut);
    let dateFinSemaine1 = new Date(dateDebut)
    let dateDebutSemaine2 = new Date(dateDebut);
    let dateFinSemaine2 = new Date(dateDebut)
    let dateDebutSemaine3 = new Date(dateDebut);
    let dateFinSemaine3 = new Date(dateDebut)
    let dateDebutSemaine4 = new Date(dateDebut);
    let dateFinSemaine4 = new Date(dateDebut)
    let dateDebutSemaine5 = new Date(dateDebut);
    let dateFinSemaine5 = new Date(dateDebut)
    
    if (dateDebutSemaine1.getDay() === 0){
        dateDebutSemaine1.setDate(jour1DuMois.getDate() + 1);
        dateFinSemaine1.setDate( dateDebutSemaine1.getDate()+5)    
    } else {
        dateFinSemaine1.setDate((6 - jour1DuMois.getDay())+jour1DuMois.getDate())
    }
    semaines.push({
      semaineId: 1,
      dateDebut: dateDebutSemaine1,
      dateFin: dateFinSemaine1,
      nbreJour: calculerNombreJours(dateDebutSemaine1, dateFinSemaine1),
      objectifHebdo:'0.00'
    });
   
    dateDebutSemaine2.setDate(dateFinSemaine1.getDate() + 2);
    dateFinSemaine2.setDate(dateFinSemaine1.getDate() + 7) 
    semaines.push({
      semaineId: 2,
      dateDebut: dateDebutSemaine2,
      dateFin: dateFinSemaine2,
      nbreJour: calculerNombreJours(dateDebutSemaine2, dateFinSemaine2),
      objectifHebdo:'0.00'
    }); 

    dateDebutSemaine3.setDate(dateFinSemaine2.getDate() + 2);
    dateFinSemaine3.setDate(dateFinSemaine2.getDate() + 7) 
    semaines.push({
      semaineId: 3,
      dateDebut: dateDebutSemaine3,
      dateFin: dateFinSemaine3,
      nbreJour: calculerNombreJours(dateDebutSemaine3, dateFinSemaine3),
      objectifHebdo:'0.00'
    }); 

    dateDebutSemaine4.setDate(dateFinSemaine3.getDate() + 2);
    dateFinSemaine4.setDate(dateFinSemaine3.getDate() + 7) 
    semaines.push({
      semaineId: 4,
      dateDebut: dateDebutSemaine4,
      dateFin: dateFinSemaine4,
      nbreJour: calculerNombreJours(dateDebutSemaine4, dateFinSemaine4),
      objectifHebdo:'0.00'
    }); 

    dateDebutSemaine5.setDate(dateFinSemaine4.getDate() + 2);
    if ((dateFinSemaine4.getDate() + 7) > dernierJourMois.getDate()) {
          dateFinSemaine5.setDate(dernierJourMois.getDate())
    } else {
          dateFinSemaine5.setDate(dateFinSemaine4.getDate() + 7)
    }  
    semaines.push({
      semaineId: 5,
      dateDebut: dateDebutSemaine5,
      dateFin: dateFinSemaine5,
      nbreJour: calculerNombreJours(dateDebutSemaine5, dateFinSemaine5),
      objectifHebdo:'0.00'
    });
    console.log(semaines)
    setMappageSemaine(semaines);
    return semaines;
  }

  function miseFormeDateTable(date1, date2) {
    return (`Du ${date1.getDate()} Au ${date2.getDate()}`)
  }
  

  const fetchObjectifs = async () => {
    setError('');
    try {
      const response = await axios.get(`${adresseServeurBackEnd}/objectifsHebdomadaires/${annee}/${mois}`, {
        headers: { Authorization: `Bearer ${fichePersonnel.token}` }
      });
      
      let dataRecue = response.data.objectif; 
      let dataRecueFormat = []
      console.log(dataRecue);
      if (dataRecue[0].objectifs.length === 0) {
        dataRecueFormat = dataRecue.map((item) => (
          {
            ...item,
            objectifs : decouperMoisEnSemaines(annee,mois)
          }    
        ));
        setObjectif(dataRecueFormat);
        setObjectifInstitut(0.00)
      } else {
        setObjectif(dataRecue);
        recalculerObjectifInstitut(dataRecue);
      }
  
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
        navigate('/gestion');
        setPageConnexion(false);
      } else {
        console.error('Erreur lors de la récupération des objectifs:', error);
        setError('Erreur lors de la récupération des objectifs.');
      }
    }
  };
  

 
  useEffect(() => {
    setContenuPageDroite(<InfoObjectifVide/>)
    fetchObjectifs();
    setInfoGestionObjectif('');
    decouperMoisEnSemaines(annee, mois);
  }, [adresseServeurBackEnd, annee, mois]);



  const handleChangeMois = (e) => {
    setInfoGestionObjectif('');
    setMois(e.target.value);
  };

  const handleChangeAnnee = (e) => {
    setInfoGestionObjectif('');
    setAnnee(e.target.value);
  };


// Fonction de recalcul des objectifs mensuels pour chaque employé
const recalculerObjectifMensuel = (employeObjectifs) => {
  const objectifMensuel = employeObjectifs.objectifs.reduce(
    (total, objHebdo) => total + parseFloat(objHebdo.objectifHebdo || 0), 0
  );
  return objectifMensuel;
};

// Fonction de recalcul de l'objectif total de l'institut
  const recalculerObjectifInstitut = (objectifs) => {
    const totalInstitut = objectifs.reduce((total, employe) => {
      return total + recalculerObjectifMensuel(employe);
    }, 0);
    setObjectifInstitut(totalInstitut.toFixed(2));
  };

const handleObjectifChange = (employeId, newObjectifs) => {
  console.log('newObjectifs -> Gestion: ',newObjectifs)
  const updatedObjectifs = objectif.map(employe =>
      employe.employeId === employeId
          ? { ...employe, objectifs: newObjectifs, objectifMensuel: recalculerObjectifMensuel({ objectifs: newObjectifs }) }  // Mettez à jour les objectifs de cet employé
          : employe
  );
  setObjectif(updatedObjectifs); // Mettez à jour l'état global
  recalculerObjectifInstitut(updatedObjectifs);
  setInfoGestionObjectif('');
};




  const handleRecupererValeurs = async () => {
    const data = { annee, mois, objectif };
    console.log("Données envoyées:", data);
    console.log(JSON.stringify( data, null, 2));
    console.log(`${adresseServeurBackEnd}/ajouterObjectifsMensuels`)


    try {
      const response = await axios.post(`${adresseServeurBackEnd}/ajouterObjectifsMensuels`, data, {
        headers: {
          Authorization: `Bearer ${fichePersonnel.token}`,
          'Content-Type': 'application/json'
        }
      });
      setInfoGestionObjectif('Objectifs mensuels ajoutés avec succès.');
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
        navigate('/gestion');
        setPageConnexion(false);
      } else {
        console.error("Erreur lors de l'ajout des objectifs mensuels:", error);
        setInfoGestionObjectif("Erreur lors de l'ajout des objectifs mensuels. Veuillez réessayer.");
      }
    }
    fetchObjectifs();
  };

 




  return (
    <div className='objectifs-manager'>
      <h2>Gestion des objectifs des employés</h2>
      <div className='bloc-annee-mois-objectif'>
        <div className='mois-objectif'>
          Mois: 
          <select value={mois} onChange={(e) => handleChangeMois(e)}>
            {moisOptions.map((mois) => (
              <option key={mois} value={mois}>{mois}</option>
            ))}
          </select>
        </div>
        <div className='annee-objectif'>
          Année: 
          <input type="number" value={annee} onChange={(e) => handleChangeAnnee(e)} />
        </div>
        <div className='objectifs-institut'>
          Objectif de l'institut: <span className='montant'> {objectifInstitut}€ </span>
        </div>
      </div>
      <div className='container-objectifs-table'>
        <table className='objectif-table'>
        <thead>
        <tr>
          <th>Nom Prénom</th>
          {mappageSemaine.map((donnee, index) => (
                  <th key={index}>
                    Semaine {index +1} <br/> {miseFormeDateTable(donnee.dateDebut, donnee.dateFin)} <br/> {donnee.nbreJour} jours
                  </th>
              ))}
              <th>Total mensuel</th>
            </tr>
          </thead>
          <tbody>
            {objectif.map((employe) => (
               <SaisieObjectifs 
               key={employe.employeId}
               employe={employe}
               onObjectifChange={handleObjectifChange} // Passer la fonction de changement
           />
              ))}
          </tbody>
        </table>
        <div className='btn-objectifs'>
          <button onClick={() => handleRecupererValeurs()}>Sauvegarder les Objectifs</button>
        </div>
        {infoGestionObjectif}
      </div>
    </div>
  );
};

export default GestionObjectifs;