import React from 'react'
import { useMonContexte } from '../MonContext';
import './affichageClientVide.css';

const AfficheClientVide = () => {


    const {setContenuPageCentrale, ficheClient} =  useMonContexte();


  return (
    <div className='container-menu-client'>
         <div className='text-fonction-menu'>
            Cliente:
        </div>
        <div className='bloc-fiche-ident'>                
                <div className='champ-affichage-selection-client'>
                    Nom: <span>-</span>
                </div>
                <div className='champ-affichage-selection-client'>
                    Prénom: <span>-</span>
                </div>
                <div className='champ-affichage-selection-client'>
                    Téléphone:<span>-</span> 
                </div>
        </div>
        <div className='bloc-text-client'>    
           <p>Veuillez Sélectionner un Client</p>
        </div>
    </div>
  )
}

export default AfficheClientVide