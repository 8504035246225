
import React, { createContext, useContext, useState } from 'react';
import axios from 'axios'; // Assure-toi d'importer axios si tu utilises des requêtes
import PageConnexion from './Connexion/PageConnexion';
import AffichageClientSelectionnee from './PageTemplete/AffichageClientSelectionnee';
import CalendrierPersonnel from './Calendrier/CalendrierPersonnel';
import AfficheClientVide from './Client/AfficheClientVide';

const MonContexte = createContext();

let ficheClientTempo = {
    id: 8,
    nom: 'TAYLOR',
    prenom: 'Alyson',
    dateNaissance: '16/06/2004',
    telephone: '0692323575'
};

let ficheUserTempo = {
    id: 1,
    nom: 'Bennett',
    prenom: 'Gill',
    fonction: 'Manager'
};

let fichePresta = {
  id: 1, 
  nom: 'P-VSP Main', 
  description: 'Pose vernis Couleur semi permanent', 
  duree: 30, 
  prix: '20.00'
};

let listePerson = {
  id: 1, 
  nom: 'TAYLOR', 
  prenom: 'Alyce'
};

let backend = 'https://apinail.maricourt.ovh';

const MonContext = ({ children }) => {

  const [pageConnexion, setPageConnexion] = useState(false);
  const [adresseServeurBackEnd, setAdresseServeurBackEnd] = useState(backend);
  const [infoConnexion, setInfoConnexion] = useState('Connectée');
  const [ficheClient, setFicheClient] = useState(ficheClientTempo);
  const [fichePersonnel, setFichePersonnel] = useState(ficheUserTempo);
  const [fichePersonnelSelect, setFichePersonnelSelect] = useState(ficheUserTempo);
  const [fichePrestation, setFichePrestation] = useState([fichePresta]);
  const [fichePrestaSelect, setFichePrestaSelect] = useState([fichePresta]);
  const [listePersonnel, setListePersonnel] = useState([listePerson]);
  const [objectifs, setObjectifs] = useState([]);
  const [encours, setEncours] = useState(150);
  const [basculeRDVClient, setBasculeRDVClient] = useState(false);
  const [memos, setMemos] = useState([]);
  const [nbreMessage, setNbreMessage] = useState(0);

  const [contenuPageCentrale, setContenuPageCentrale] = useState(<CalendrierPersonnel />);
  const [contenuPageDroite, setContenuPageDroite] = useState(<AfficheClientVide />);
  const [affichage, setAffichage] = useState(<PageConnexion />);

  // Fonction pour gérer les erreurs Axios
  const handleAxiosError = async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        console.error('Erreur 401 : Token invalide.');
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login';  // Redirection vers la page de connexion

      } else if (error.response.status === 403) {
        console.error('Erreur 403 : Token expiré, tentative de renouvellement...');
        const refreshToken = localStorage.getItem('refreshToken');

        if (!refreshToken) {
          console.error('Aucun refresh token trouvé.');
          window.location.href = '/gestion';
          return;
        }

        try {
          const refreshResponse = await axios.post(`${backend}/token/refresh`, { refreshToken });
          const newToken = refreshResponse.data.token;
          localStorage.setItem('jwtToken', newToken);
          console.log('Token renouvelé avec succès.');
          return newToken;

        } catch (refreshError) {
          console.error('Erreur lors du renouvellement du token:', refreshError);
          localStorage.removeItem('jwtToken');
          localStorage.removeItem('refreshToken');
          window.location.href = '/gestion';
        }
      } else {
        console.error(`Erreur ${error.response.status} : ${error.response.statusText}`);
      }
    } else if (error.request) {
      console.error('Aucune réponse reçue du serveur:', error.request);
    } else {
      console.error('Erreur de configuration de la requête:', error.message);
    }

    throw error; // Propager l'erreur
  };

  // Partager les états et fonctions avec les autres composants via le contexte
  const contextPartager = {
    pageConnexion, setPageConnexion, affichage, setAffichage, infoConnexion, setInfoConnexion,
    ficheClient, setFicheClient, fichePersonnel, setFichePersonnel, contenuPageCentrale, setContenuPageCentrale,
    contenuPageDroite, setContenuPageDroite, adresseServeurBackEnd, setAdresseServeurBackEnd,
    fichePrestation, setFichePrestation, listePersonnel, setListePersonnel, objectifs, setObjectifs, encours,
    setEncours, basculeRDVClient, setBasculeRDVClient, memos, setMemos, nbreMessage, setNbreMessage, fichePersonnelSelect, setFichePersonnelSelect,
    handleAxiosError, fichePrestaSelect, setFichePrestaSelect 
  };

  return (
    <MonContexte.Provider value={contextPartager}>
      {children}
    </MonContexte.Provider>
  );
};

export const useMonContexte = () => useContext(MonContexte);
export default MonContext;

