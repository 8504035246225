
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './gestionEmployes.css';
import { useMonContexte } from '../MonContext';
import { useNavigate } from 'react-router-dom';
import InformationPersonnel from './InformationPersonel';
import AjoutPersonnel from './AjoutPersonnel';
import InfoPersonnelVide from './InfoPersonnelVide';

const GestionEmployes = () => {
  const { adresseServeurBackEnd, contenuPageDroite, setContenuPageDroite,setContenuPageCentrale, fichePersonnel, 
          setPageConnexion, fichePersonnelSelect, setFichePersonnelSelect } = useMonContexte();

  const [employees, setEmployees] = useState([]);
  const [form, setForm] = useState({
    id: null,
    nom: '',
    prenom: '',
    username: '',
    password: '',
    fonction: '',
    couleurEDT: '#FFFFFF', // Ajouter la couleur par défaut (blanc par exemple)
  });
  const [error, setError] = useState('');
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();


  const fetchEmployees = async () => {
    try {
        const response = await axios.get(`${adresseServeurBackEnd}/listeEmployes`,
        {headers:{ Authorization: `Bearer ${fichePersonnel.token}`}
        });
      if (Array.isArray(response.data)) {
        setEmployees(response.data);
      } else {
        setError('La réponse de l\'API n\'est pas un tableau');
        console.error('La réponse de l\'API n\'est pas un tableau', response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
        navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
        setPageConnexion(false)
      } else {      
        setError('Erreur lors du chargement des employés');
        console.error('Erreur lors du chargement des employés', error);
      }
    }
  };

  useEffect(() => {
    fetchEmployees();
    setContenuPageDroite(<InfoPersonnelVide/> )
  }, []);

  
  const handleSelection = (employee) => {
    console.log('id employé sélectionné: ',employee.id)
    setFichePersonnelSelect(employee);
    setContenuPageDroite(<InformationPersonnel/>)
  };

  

  return (
    <div className="employees-manager">
      <h1>Gestion des Personnels</h1>
          <div className="btn-gestion-personnel"> 
            <button onClick={() => setContenuPageCentrale(<AjoutPersonnel/>)}> Nouveau Personnel</button> 
            <button onClick={() => setContenuPageCentrale(<AjoutPersonnel/>)}> Restaurer Personnel</button>  
          </div>
          <div className="container-personnel-table">
            <table className="employees-table">
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Prénom</th>
                  <th>Username</th>
                  <th>Fonction</th>
                  <th>Couleur</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {employees
                  .sort((a, b) => a.nom.localeCompare(b.nom))
                  .map((employee) => (
                    <tr key={employee.id}>
                      <td>{employee.nom}</td>
                      <td>{employee.prenom}</td>
                      <td>{employee.username}</td>
                      <td>{employee.fonction}</td>
                      <td><div className='couleur-personnel' style={{backgroundColor:employee.couleurEDT}} ></div> </td>
                      <td className="btn-ajouter-action">
                        <button className="edit-button" onClick={() => handleSelection(employee)}>
                              Sélectionner
                        </button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>  
     </div> 
  );
};

export default GestionEmployes;