import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useMonContexte } from '../MonContext';
import axios from 'axios';

const Depense = () => {
    const { adresseServeurBackEnd, fichePersonnel, ficheClient, setPageConnexion } = useMonContexte();

    const [depenseClient, setDepenseClient] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const fetchDepense = async () => {
        setError('');
        try {
          const response = await axios.get(`${adresseServeurBackEnd}/prestationclients/client/${ficheClient.id}`, {
            headers: { Authorization: `Bearer ${fichePersonnel.token}` }
        });
        setDepenseClient(response.data);
            console.log(response.data);
        } catch (error) {
          if (error.response && error.response.status === 403) {
            console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
            navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
            setPageConnexion(false)
          } else {
            console.error('Erreur lors de la récupération des employés:', error);
            setError('Erreur lors de la récupération des employés.');
          }
        }
      };

    const conversionDate = (dateString) => {
        const date = new Date(dateString);
        // Récupérer les éléments de la date
        const jour = String(date.getUTCDate()).padStart(2, '0'); // Jour avec un zéro initial si nécessaire
        const mois = String(date.getUTCMonth() + 1).padStart(2, '0'); // Les mois commencent à 0, donc ajouter 1
        const annee = date.getUTCFullYear();        
        const heures = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');        
        // Formater la date en JJ/MM/AAAA HH:MM
        const dateFormatee = `${jour}/${mois}/${annee} ${heures}:${minutes}`;
        return dateFormatee;
    }

    
    useEffect(() => {
        fetchDepense();
    },[])


  return (    
    <div className='bloc-memo-modif'> 
      <div className='list-memo-modif'>
              {depenseClient.map((depense) => (
                <div key={depense.id} className='div-memo-modif-map' > 
                    <div className='memo-modif-item'>                      
                        <p> {conversionDate(depense.date)}: <strong>{depense.prixTotal} €</strong></p>            
                    </div>
                </div>                  
              ))}
        </div>
    </div>
  )
}

export default Depense