
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMonContexte } from '../MonContext';
import axios from 'axios';
import'./recupHistorique.css'

const RecupHistorique = () => {
    const { adresseServeurBackEnd, fichePersonnel, ficheClient, setPageConnexion } = useMonContexte();
    
    const [histoClient, setHistoClient] = useState([]); // Contient les rendez-vous et leurs prestations
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const fetchHistorique = async () => {
        setError('');
        try {
            const response = await axios.get(`${adresseServeurBackEnd}/rendezvous/client/${ficheClient.id}`, {
                headers: { Authorization: `Bearer ${fichePersonnel.token}` }
            });
            setHistoClient(response.data); // Assumes the API returns an array of rendez-vous with prestations included
        } catch (error) {
            if (error.response && error.response.status === 403) {
                console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
                navigate('/gestion'); // Redirige vers la page de connexion en cas de token expiré ou invalide
                setPageConnexion(false);
            } else {
                console.error('Erreur lors de la récupération des rendez-vous:', error);
                setError('Erreur lors de la récupération des rendez-vous.');
            }
        }
    };

    const conversionDate = (dateString) => {
        const date = new Date(dateString);
        const jour = String(date.getUTCDate()).padStart(2, '0');
        const mois = String(date.getUTCMonth() + 1).padStart(2, '0');
        const annee = date.getUTCFullYear();
        const heures = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        return `${jour}/${mois}/${annee} à ${heures}:${minutes}`;
    };

    useEffect(() => {
        fetchHistorique();
    }, []); // Only runs on component mount

    return (
        <div className='bloc-memo-modif'>
            {error && <p className='error-message'>{error}</p>}
            <div className='list-memo-modif'>
                {histoClient.map((rdv) => (
                    <div key={rdv.id} className='div-memo-modif-map'>
                        <div className='memo-modif-item'>
                            <p>Le <span>{conversionDate(rdv.dateHeure)}</span> avec <strong>{rdv.prenomEmploye}</strong>:</p>
                            <ul>
                                {rdv.prestations.map((presta, index) => (  // Access prestations from the rendezvous
                                    <li key={index}>{presta.nom}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RecupHistorique;