
import React, { useEffect, useState } from 'react';
import { useMonContexte } from '../MonContext';
import './saisirHorairePersonnel.css';
import imgFlecheGauche from '../image/icons8-fleche-gauche-80.png';
import imgFlecheDroite from '../image/icons8-fleche-droite-80.png';
import axios from 'axios';



const SaisirHorairePersonnel = () => {
  const { adresseServeurBackEnd, fichePersonnel, fichePersonnelSelect } = useMonContexte();

  const [ joursDeLaSemaine, setJoursDeLaSemaine] = useState([
          {  jour: 'Lundi', debutService: '', debutPause: '' , finPause: '', finService: '', matinOff: '', apresMidisOff:'', heureJournalier:''}, 
          {  jour: 'Mardi', debutService: '', debutPause: '' , finPause: '', finService: '', matinOff: '', apresMidisOff:'', heureJournalier:''}, 
          {  jour: 'Mercredi', debutService: '', debutPause: '' , finPause: '', finService: '', matinOff: '', apresMidisOff:'', heureJournalier:''}, 
          {  jour: 'Jeudi', debutService: '', debutPause: '' , finPause: '', finService: '', matinOff: '', apresMidisOff:'', heureJournalier:''}, 
          {  jour: 'Vendredi', debutService: '', debutPause: '' , finPause: '', finService: '', matinOff: '', apresMidisOff:'', heureJournalier:''}, 
          {  jour: 'Samedi', debutService: '', debutPause: '' , finPause: '', finService: '', matinOff: '', apresMidisOff:'', heureJournalier:''}, 
      ]);

  
  const joursMapping = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

  const [totalJourLundi, setTotalJourLundi ] = useState(0.0);
  const [totalJourMardi, setTotalJourMardi ] = useState(0.0);
  const [totalJourMercredi, setTotalJourMercredi ] = useState(0.0);
  const [totalJourJeudi, setTotalJourJeudi ] = useState(0.0);
  const [totalJourVendredi, setTotalJourVendredi ] = useState(0.0);
  const [totalJourSamedi, setTotalJourSamedi ] = useState(0.0);
  const [totalSemaine, setTotalSemaine ] = useState(0.0);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [toggle, setToggle ] = useState(false);


  const getStartOfWeek = (date) => {
    const day = date.getDay(); // Jour actuel de la semaine (0 = Dimanche, 1 = Lundi, etc.)
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // Corrige si le jour est Dimanche
    const startOfWeek = new Date(date.setDate(diff));
    startOfWeek.setHours(0, 0, 0, 0); // Réinitialise l'heure à 00:00:00 (début de la journée)
    return startOfWeek;
  };
  
  const getEndOfWeek = (startOfWeek) => {
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 5); // Jusqu'au Samedi
    endOfWeek.setHours(23, 59, 59, 999); // Fin du samedi à 23:59:59 (fin de la journée)
    return endOfWeek;
  };;

  const startOfWeek = getStartOfWeek(currentDate);
  const endOfWeek = getEndOfWeek(startOfWeek);
  


  const formatDate = (date) => {
      return date.toLocaleDateString('fr-FR', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
      });
  };

  // Fonction pour changer de semaine
  const changeWeek = (direction) => {
      const newDate = new Date(currentDate);
      newDate.setDate(newDate.getDate() + direction * 7); // +/- 7 jours
      setCurrentDate(newDate);
  };



  const fetchHorairesForWeek = async () => {
      try {
        const startDate = startOfWeek.toISOString().split('T')[0];
        const endDate = endOfWeek.toISOString().split('T')[0];
    
        const response = await axios.get(`${adresseServeurBackEnd}/emploiDuTempsSemaine/${fichePersonnelSelect.id}`, {
          params: {
            startDate,
            endDate
          },
          headers: {
            Authorization: `Bearer ${fichePersonnel.token}`
          }
        });
        setJoursDeLaSemaine(response.data)
        const dataFetch = response.data;
        if (dataFetch[0].totalHeuresSemaine === '') {
             miseAJourFetch('');
          } else {
              dataFetch.map((element) => {
              miseAJourDureeJournaliere(element.jour, element.heureJournalier);
              setTotalSemaine(dataFetch[0].totalHeuresSemaine)
            })
          }
      } catch (error) {
        console.error('Erreur lors de la récupération des horaires :', error);
      }
    };
    
    // Appeler fetchHorairesForWeek à chaque changement de semaine ou de currentDate
    useEffect(() => {
      fetchHorairesForWeek();
    }, [currentDate]);
    
   


  const updateJourDeLaSemaine = (jour, champ, valeur) => {
      setJoursDeLaSemaine((prevState) => 
          prevState.map((item) => 
              item.jour === jour ? { ...item, [champ]: valeur } : item
          )
      );
      setToggle(!toggle);
  };

  const getValeurChamp = (jour, champ) => {
      const jourTrouve = joursDeLaSemaine.find((item) => item.jour === jour);
      return jourTrouve ? jourTrouve[champ] : null;
  };
  
  // Calcul de la durée journalière sans mise à jour de l'état
  const calculerDureeJournalier = (jour) => {
    const jourTrouve = joursDeLaSemaine.find((element) => element.jour === jour);
  
    if (!jourTrouve) return 0;
  
    let dureeMatin = 0;
    let dureeApresMidi = 0;
  
    // Assurer que `matinOff` et `apresMidisOff` sont bien des nombres
    const matinOff = parseInt(jourTrouve.matinOff, 10);
    const apresMidisOff = parseInt(jourTrouve.apresMidisOff, 10);
  
    // Calcul de la durée du matin
    if (matinOff !== 1) {
      const debutService = jourTrouve.debutService ? new Date(`1970-01-01T${jourTrouve.debutService}`) : null;
      const debutPause = jourTrouve.debutPause ? new Date(`1970-01-01T${jourTrouve.debutPause}`) : null;
  
      if (debutService && debutPause && !isNaN(debutService) && !isNaN(debutPause)) {
        dureeMatin = (debutPause - debutService) / (1000 * 60 * 60); // Conversion en heures
      }
    }
  
    // Calcul de la durée de l'après-midi
    if (apresMidisOff !== 1) {
      const finPause = jourTrouve.finPause ? new Date(`1970-01-01T${jourTrouve.finPause}`) : null;
      const finService = jourTrouve.finService ? new Date(`1970-01-01T${jourTrouve.finService}`) : null;
  
      if (finPause && finService && !isNaN(finPause) && !isNaN(finService)) {
        dureeApresMidi = (finService - finPause) / (1000 * 60 * 60); // Conversion en heures
      }
    }
  
    const dureeTotale = dureeMatin + dureeApresMidi;
    
    // Arrondir à 2 décimales
    const dureeArrondie = Math.round(dureeTotale * 100) / 100;
    console.log('dureeTotale: ', dureeArrondie);
  
    // Mise à jour de la durée journalière
    miseAJourDureeJournaliere(jour, dureeArrondie);
  
    return dureeArrondie;
  };
  

useEffect(() => {
calculerDureeSemaine()
},[toggle])

const miseAJourFetch = (valeur) => {
    joursDeLaSemaine.map((element) => {
      switch (element.jour) {
        case 'Lundi':
            setTotalJourLundi(valeur);
          break;
          case 'Mardi':
            setTotalJourMardi(valeur);
          break;
          case 'Mercredi':
            setTotalJourMercredi(valeur);
          break;
          case 'Jeudi':
            setTotalJourJeudi(valeur);
          break;
          case 'Vendredi':
            setTotalJourVendredi(valeur);
          break;
          case 'Samedi':
            setTotalJourSamedi(valeur);
          break;
        default:
          break;
      }
  })
}


  const calculerDureeSemaine = () => {
      let dureeTotale = 0;        
      joursDeLaSemaine.forEach((item) => {
          const dureeJour = calculerDureeJournalier(item.jour);
          if (dureeJour !== null) {
              dureeTotale += dureeJour;
          }
      });
     setTotalSemaine(dureeTotale); 
  };


  const handleMatinOffChange = (jour) => {
      setJoursDeLaSemaine((prevState) =>
          prevState.map((item) => {
              if (item.jour === jour) {
                  if (item.matinOff === 1) {
                      // Si le matin est déjà off, réactive-le
                      return {
                          ...item,
                          matinOff: 0,
                      };
                      setToggle(!toggle)
                  } else {
                      // Sinon, mets le matin off
                      return {
                          ...item,
                          matinOff: 1,
                          debutService: '',
                          debutPause: '',
                      };
                      setToggle(!toggle)
                  }
              }
              return item;
          })
      );
  };
  
  const handleApresMidiOffChange = (jour) => {
      setJoursDeLaSemaine((prevState) =>
          prevState.map((item) => {
              if (item.jour === jour) {
                  if (item.apresMidisOff === 1) {
                      // Si l'après-midi est déjà off, réactive-le
                      return {
                          ...item,
                          apresMidisOff: 0,                           
                      };
                      setToggle(!toggle)
                  } else {
                      // Sinon, mets l'après-midi off
                      return {
                          ...item,
                          apresMidisOff: 1,
                          finPause: '',
                          finService: ''
                      };
                    setToggle(!toggle)
                  }
              }
              setToggle(!toggle)
              return item;
          })
      );
  };
  
  const miseAJourDureeJournaliere = (jour, valeur) =>{
      switch (jour) {
        case 'Lundi':
            setTotalJourLundi(valeur);
          break;
          case 'Mardi':
            setTotalJourMardi(valeur);
          break;
          case 'Mercredi':
            setTotalJourMercredi(valeur);
          break;
          case 'Jeudi':
            setTotalJourJeudi(valeur);
          break;
          case 'Vendredi':
            setTotalJourVendredi(valeur);
          break;
          case 'Samedi':
            setTotalJourSamedi(valeur);
          break;
        default:
          break;
      }

  }

  const handleEffacer = () => {
      setJoursDeLaSemaine([
          {  jour: 'Lundi', debutService: '', debutPause: '' , finPause: '', finService: '', matinOff: '', apresMidisOff:'', heureJournalier:''}, 
          {  jour: 'Mardi', debutService: '', debutPause: '' , finPause: '', finService: '', matinOff: '', apresMidisOff:'', heureJournalier:''}, 
          {  jour: 'Mercredi', debutService: '', debutPause: '' , finPause: '', finService: '', matinOff: '', apresMidisOff:'', heureJournalier:''}, 
          {  jour: 'Jeudi', debutService: '', debutPause: '' , finPause: '', finService: '', matinOff: '', apresMidisOff:'', heureJournalier:''}, 
          {  jour: 'Vendredi', debutService: '', debutPause: '' , finPause: '', finService: '', matinOff: '', apresMidisOff:'', heureJournalier:''}, 
          {  jour: 'Samedi', debutService: '', debutPause: '' , finPause: '', finService: '', matinOff: '', apresMidisOff:'', heureJournalier:''}, 
      ]);
  };

  
  const handleEnregistreEvent = async () => {
    const events = {};
    const startOfWeek = getStartOfWeek(new Date(currentDate)); // Appel à ta fonction getStartOfWeek
    
    joursDeLaSemaine.map((element, index) => {
        const date = new Date(startOfWeek); // Cloner l'objet Date pour éviter de modifier la date originale
        date.setDate(date.getDate() + index); // Ajouter les jours à partir du lundi
        const formattedDate = date.toISOString().split('T')[0]; // Format YYYY-MM-DD
        
        events[formattedDate] = {
            employeId: fichePersonnelSelect.id,
            date: formattedDate,
            heureArrivee: element.debutService || null,
            heurePauseDebut: element.debutPause || null,
            heurePauseFin: element.finPause || null,
            heureSortie: element.finService || null,
            matinOff: element.matinOff,
            apresMidiOff: element.apresMidisOff,
            heureTotaleJour: calculerDureeJournalier(element.jour),
            totalHeuresSemaine: totalSemaine,
        };
      }
    );      
    const eventsArray = Object.values(events);       
    try {
        const response = await axios.post(`${adresseServeurBackEnd}/emploiDuTemps`, eventsArray, {
            headers: {
                Authorization: `Bearer ${fichePersonnel.token}`,
                'Content-Type': 'application/json',
            },
        });
        alert('Les horaires ont été enregistrés avec succès.');
    } catch (error) {
        console.error('Erreur lors de l\'enregistrement :', error);
        alert('Erreur lors de la communication avec le serveur.');
    }
};



  
    return (
      <div className='container-saisie-horaire'>
        <div className='bloc-titre-planning'>
          <div className='titre-planning'>
            <h2>Les horaires de {fichePersonnelSelect.prenom}</h2>
          </div>
          <div className='bloc-fleche-planning'>
            <div className='fleche-gauche-planning'>
                <button onClick={() => changeWeek(-1)}>
                    <img src={imgFlecheGauche} alt='fleche-Gauche' />
                </button> 
            </div>
            <div className='semaine-planning'>
                Du {formatDate(startOfWeek)} au {formatDate(endOfWeek)} 
            </div>
            <div className='fleche-droite-planning'>
                <button onClick={() => changeWeek(1)}>
                    <img src={imgFlecheDroite} alt='fleche-Droite' />
                </button>
            </div>   
          </div>
        </div>    

        <div className='container-table-saisi-horaire'>
        <table className='table-horaire'>
          <thead>
              <tr>
                <th></th>                  
                {/*<th>Lundi</th><th>Mardi</th><th>Mercredi</th><th>Jeudi</th><th>Vendredi</th><th>Samedi</th>*/}                  
                {joursDeLaSemaine.map((element, index) => (
                    <th key={element.jour}>
                      {element.jour} 
                    </th>                  
                ))}
              </tr>
          </thead>
          <tbody>    
            <tr>
              <td className='td-table-horaire'>Matin OFF</td>               
              <td className='td-input-chbx'>
                  <input type="checkbox" checked={getValeurChamp('Lundi', 'matinOff')} onChange={() => handleMatinOffChange('Lundi')}/>
              </td>
              <td className='td-input-chbx'>
                  <input type="checkbox" checked={getValeurChamp('Mardi', 'matinOff')} onChange={() => handleMatinOffChange('Mardi')}/>
              </td>
              <td className='td-input-chbx'>
                  <input type="checkbox" checked={getValeurChamp('Mercredi', 'matinOff')} onChange={() => handleMatinOffChange('Mercredi')}/>
              </td>
              <td className='td-input-chbx'>
                  <input type="checkbox" checked={getValeurChamp('Jeudi', 'matinOff')} onChange={() => handleMatinOffChange('Jeudi')}/>
              </td>
              <td className='td-input-chbx'>
                  <input type="checkbox" checked={getValeurChamp('Vendredi', 'matinOff')} onChange={() => handleMatinOffChange('Vendredi')}/>
              </td>
              <td className='td-input-chbx'>
                  <input type="checkbox" checked={getValeurChamp('Samedi', 'matinOff')} onChange={() => handleMatinOffChange('Samedi')}/>
              </td>
            </tr>             
            
            <tr>
              <td>Début du service</td>
              {joursDeLaSemaine.map((element, index) => (
                    <td className='td-input-time'>
                    <input type="time" value={getValeurChamp(element.jour, 'debutService')}
                      onChange={(e) => updateJourDeLaSemaine(element.jour, 'debutService', e.target.value)}
                      disabled={getValeurChamp(element.jour, 'matinOff')}/>
                  </td>                 
                ))}               
            </tr>
            
            <tr>
              <td>Début de la pause</td>                  
                {joursDeLaSemaine.map((element, index) => (
                     <td className='td-input-time'>
                     <input type="time" value={getValeurChamp(element.jour, 'debutPause')}
                       onChange={(e) => updateJourDeLaSemaine(element.jour, 'debutPause', e.target.value)}
                       disabled={getValeurChamp(element.jour, 'matinOff')}/>
                   </td>                
                ))} 
            </tr> 

            <tr>
              <td>Après-midi OFF</td>
                {joursDeLaSemaine.map((element, index) => (
                  <td className='td-input-chbx'>
                     <input type="checkbox" checked={getValeurChamp(element.jour, 'apresMidisOff')} 
                        onChange={() => handleApresMidiOffChange(element.jour)} />
                  </td>                
                ))}              
            </tr>
           
            <tr>
              <td>Fin de la pause</td>
                {joursDeLaSemaine.map((element, index) => (
                  <td className='td-input-time'>
                      <input type="time" value={getValeurChamp(element.jour, 'finPause')}
                        onChange={(e) => updateJourDeLaSemaine(element.jour, 'finPause', e.target.value)} 
                        disabled={getValeurChamp(element.jour, 'apresMidisOff')}/>
                  </td>                
                ))}
            </tr>
           
            <tr>
              <td>Fin du service</td>
                {joursDeLaSemaine.map((element, index) => (
                  <td className='td-input-time'>
                    <input type="time" value={getValeurChamp(element.jour, 'finService')}
                        onChange={(e) => updateJourDeLaSemaine(element.jour, 'finService', e.target.value)}
                        disabled={getValeurChamp(element.jour, 'apresMidisOff')}/>
                  </td>                
                ))}
            </tr>

            <tr>
                <td>
                  Nbre d'heures
                </td>
                <td>
                  {totalJourLundi}
                </td>
                <td>
                  {totalJourMardi}
                </td>
                <td>
                  {totalJourMercredi}
                </td>
                <td>
                  {totalJourJeudi}
                </td>
                <td>
                  {totalJourVendredi}
                </td>
                <td>
                  {totalJourSamedi}
                </td>
          </tr>
          </tbody>
        </table>
        <div className='heure-totale-semaine'>
            <p>Heures travaillées dans la semaine: <span> {totalSemaine} heures</span></p>
        </div>
        <div className='btn-saisi-horaire-personnel'>
          <button onClick={() => handleEffacer()}>
            Effacer les données
          </button>
          <button onClick={() => handleEnregistreEvent()}>Enregistrer</button>
        </div>
      </div>
      </div>
    );
  };

export default SaisirHorairePersonnel
