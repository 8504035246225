import React, { useEffect, useState } from 'react'
import './supprimerPrestations.css'
import { Navigate, useNavigate } from 'react-router-dom';
import { useMonContexte } from '../MonContext';
import InfoPrestation from './InfoPrestation';
import axios from 'axios';
import GestionPrestations from './GestionPrestations';
import imageDanger from '../image/icons8-danger-80.png';

const SupprimerPrestation = () => {
    

    const {adresseServeurBackEnd, fichePersonnel, setPageConnexion, setContenuPageCentrale, setContenuPageDroite, 
            fichePrestaSelect, setFichePrestaSelect} =  useMonContexte();
  
            const navigate = useNavigate();
            const [error, setError] = useState('');

            const handleDelete = async () => {
                try {
                  await axios.delete(`${adresseServeurBackEnd}/prestations/${fichePrestaSelect.id}`, {
                    headers: {
                        Authorization: `Bearer ${fichePersonnel.token}`,
                        'Content-Type': 'application/json'
                    }
                });
                setContenuPageCentrale(<GestionPrestations/>);
                } catch (error) {
                  if (error.response && error.response.status === 403) {
                    console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
                    navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
                    setPageConnexion(false)
                  } else {
                    setError('Erreur lors de la suppression de la prestation');
                    console.error('Erreur lors de la suppression de la prestation', error);
                  }
                }
              };



  return (
  <div className='container-suppression-client'>
    <div className='titre-suppression-client'>
        <p>Voulez-vous supprimer : </p>
        <strong>{fichePrestaSelect.nom} <br/>  {fichePrestaSelect.description}</strong>
    </div>

    <div className='avertissement-suppression-client'>
        <div className='icone-danger-supp-client'>
            <img src={imageDanger} alt='imgedanger'/>
        </div>
        <div className='text-danger-supp-client'>
            <p>Attention la suppression de la prestation <strong>{fichePrestaSelect.nom}</strong> sera définitive!</p>
            <p>Toutes les données qui y sont attachées seront supprimées! </p>
        </div>
    </div>
    <div className='btn-suppression-client'>
      <button onClick={() => setContenuPageCentrale(<GestionPrestations/>)} > Annuler</button>          
      <button onClick={() => handleDelete()}> Supprimer</button>
    </div>
</div>
  )
}

export default SupprimerPrestation