
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useMonContexte } from './MonContext';

const RecuperationDonnee = () => {
    const {adresseServeurBackEnd, setFichePrestation, setListePersonnel, setObjectifs, fichePersonnel } = useMonContexte();
    const [error, setError] =useState('');

    const moisOptions = [
      'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
      'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
    ];
    
  function obtenirMoisActuels() {  
    const dateActuelle = new Date();
    const mois = moisOptions[dateActuelle.getMonth()];    
    return mois;
  };

  function obtenirAnneeActuels() {  
    const dateActuelle = new Date();
    const annee = dateActuelle.getFullYear().toString();     
    return annee;
  };


    const [mois, setMois] = useState(obtenirMoisActuels());
    const [annee, setAnnee] = useState(obtenirAnneeActuels());

      const fetchObjectifs = async () => {
          try {
            const response = await axios.get(`${adresseServeurBackEnd}/objectifsEmployes/${annee}/${mois}`, {
              headers: { Authorization: `Bearer ${fichePersonnel.token}` }
          });
            setObjectifs(response.data.objectifs);
          } catch (error) {
            console.error('Erreur lors de la récupération des employés:', error);
          }
      };

      const fetchPresta = async () => {
          try {
            const response = await axios.get(`${adresseServeurBackEnd}/prestations`, {
              headers: { Authorization: `Bearer ${fichePersonnel.token}` }
          });
            if (Array.isArray(response.data)) {
              setFichePrestation(response.data);
            } else {
              setError('La réponse de l\'API n\'est pas un tableau');
              console.error('La réponse de l\'API n\'est pas un tableau', response.data);
            }
          } catch (error) {
            setError('Erreur lors du chargement des clients');
            console.error('Erreur lors du chargement des clients', error);
          }
      };

      const fetchPersonnel = async () => {
          try {
            const response = await axios.get(`${adresseServeurBackEnd}/employes`, {
              headers: { Authorization: `Bearer ${fichePersonnel.token}` }
          });
            
            if (Array.isArray(response.data)) {
              setListePersonnel(response.data);
            } else {
              setError('La réponse de l\'API n\'est pas un tableau');
              console.error('La réponse de l\'API n\'est pas un tableau', response.data);
            }
          } catch (error) {
            setError('Erreur lors du chargement des clients');
            console.error('Erreur lors du chargement des clients', error);
          }
      };
/*
      useEffect(()=>{
          fetchPersonnel();
          fetchPresta();
          fetchObjectifs();
      },[])
*/
  return (
    <>
    </>
  )
}

export default RecuperationDonnee