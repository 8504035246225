
import React, { useEffect } from 'react'
import { useMonContexte } from './MonContext';
import PageConnexion from './Connexion/PageConnexion';
import PageCourante from './PageTemplete/PageCourante';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Home = ({setEtatGestion}) => {
    const {pageConnexion, affichage, setAffichage, setAdresseServeurBackEnd} =  useMonContexte();  
    const location = useLocation();

    useEffect(() => {
        setEtatGestion(true)
    }, [location.pathname]);


    
    const fonctionConnexionPage = () =>{
        if (pageConnexion) {
            setAffichage(<PageCourante/>)
        } else {
          setAffichage(<PageConnexion/>)
        }
      } 
  
    useEffect(()=>{
      fonctionConnexionPage();
    },[pageConnexion])


  return (
    <div className='div-home'>
          {affichage}
    </div>
  )
}

export default Home
