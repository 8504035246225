
import React, { useEffect, useState } from 'react'
import { useMonContexte } from '../MonContext';
import axios from 'axios';
import './photoClient.css'
import Carousel from './Carousel';
import { useNavigate } from 'react-router-dom';

const PhotoClient = () => {
    const {adresseServeurBackEnd, ficheClient, fichePersonnel, setPageConnexion} =  useMonContexte();

    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPhotos = async () => {
          console.log(`${adresseServeurBackEnd}/photos/${ficheClient.id}`)
            try {
                const response = await axios.get(`${adresseServeurBackEnd}/photos/${ficheClient.id}`,
                  {headers: {Authorization: `Bearer ${fichePersonnel.token}`}
                  });
                setPhotos(response.data);
                console.log(response.data)
                setLoading(false);
            } catch (error) {
              if (error.response && error.response.status === 403) {
                console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
                navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
                setPageConnexion(false)
              } else {
                console.error('Erreur lors de la récupération des photos:', error);
                setError('Pas de photo à afficher');
                setLoading(false);
              }
            }
        };
        fetchPhotos();
    }, [adresseServeurBackEnd, fichePersonnel.token,ficheClient.id]);
    
    if (loading) {
        return <p>Chargement des photos...</p>;
    }
    
    if (error) {
        return <p>{error}</p>;
    }


  return (
    <div className='container-photo' >
      <div className='bloc-photo' >
        <Carousel images={photos} />
      </div>
    </div>
  );
  
}

export default PhotoClient