
import React, { useEffect, useState, useCallback } from 'react';
import './infoRdv.css';
import { useMonContexte } from '../MonContext';
import SaisieRendezVous from '../RendezVous/SaisieRendezVous';
import axios from 'axios';
import FicheClient from '../Client/FicheClient';
import { useNavigate } from 'react-router-dom';
import AffichageClientSelectionnee from '../PageTemplete/AffichageClientSelectionnee';

const InfoRdv = ({ rendezVous }) => {
  const { adresseServeurBackEnd, setBasculeRDVClient, setContenuPageCentrale, setContenuPageDroite, setPageConnexion,
    setFicheClient, fichePersonnel } = useMonContexte();

  

  const [nom, setNom] = useState('');
  const [date, setDate] = useState('');
  const [debut, setDebut] = useState('');
  const [fin, setFin] = useState('');
  const [telephone, setTelephone] = useState('');
  const [prestation, setPrestation] = useState([]);
  const [employe, setEmploye] = useState('');
  const navigate = useNavigate();

  const transformationRendezVous = useCallback((rdv) => {
    setNom(`${rdv.clientNom} - ${rdv.clientPrenom}`);
    const timeDebut = new Date(rdv.dateHeure).toLocaleString('fr-FR');
    const timeFin = new Date(rdv.heureFin).toLocaleString('fr-FR');
    setDate(timeDebut.slice(0, 10));
    setDebut(timeDebut.slice(11, 16).replace(':', 'H'));
    setFin(timeFin.slice(11, 16).replace(':', 'H'));
    setPrestation(rdv.prestations || []);
    setTelephone(rdv.clientTelephone || '+00');
    setEmploye(rdv.prenomEmploye);
  }, []);

  const rechercheFicheClient = async (clientId) => {
    try {
      const response = await axios.get(`${adresseServeurBackEnd}/clients/${clientId}}`,{
        headers:{Authorization: `Bearer ${fichePersonnel.token}`}
        });
      if (response.data.length > 0) {
        setFicheClient(response.data[0]);
      } else {
        console.log('Aucun client trouvé pour ce numéro de téléphone.');
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
        navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
        setPageConnexion(false)
      } else {
      console.error('Erreur lors de la recherche du client:', error);
    }
   }
  };

  useEffect(() => {
    transformationRendezVous(rendezVous);
    setBasculeRDVClient(true);
    console.log('clientId: ',rendezVous.clientId)
    rechercheFicheClient(rendezVous.clientId);
  }, [rendezVous, transformationRendezVous, setBasculeRDVClient]);

  const handleClickFicheClient = useCallback(() => {
    setContenuPageCentrale(<FicheClient/>)    
    setContenuPageDroite(<AffichageClientSelectionnee/>)
  }, [setContenuPageDroite]);

  const handleClickOuvrirClient = useCallback(() => {
  }, []);

  const handleClickModifierRdv = useCallback(() => {
    setContenuPageCentrale(<SaisieRendezVous modeModification={true} initialData={rendezVous} />);
  }, [rendezVous, setContenuPageCentrale]);

  const handleClickSupprimerRdv = useCallback(() => {
    setContenuPageCentrale(<SaisieRendezVous modeModification={true} initialData={rendezVous} />);
  }, [rendezVous, setContenuPageCentrale]);

  return (
    <div className='container-info-rdv'>
      <h2>Info RDV</h2>
      <div className='nom-rdv'>
          {nom}      
      </div>
      <div className='horaire-rdv'>
          <strong>Rendez-vous:</strong>                
          <div className='bloc-date'>
               Le {date}
          </div>
          <div className='bloc-debut-fin'>
               De {debut} à {fin}
          </div>
      </div>

      <div className='bloc-info-presta'>
          <strong>Prestations demandées:</strong>
          <div className='presta-rdv'>
            <ul>
              {prestation.map((presta, index) => (
                <li key={index}>{presta.nom} <br/> <span className='span-presta-rdv-info' >{presta.duree}min &nbsp; {presta.prix}€</span> </li>
              ))}
            </ul>
          </div>
      </div>

      <div className='bloc-info-telephone'>
          <strong>Téléphone:</strong>
          <div className='telephone-rdv'>
            {telephone}
          </div>
      </div>


      <div className='bloc-info-employe'>
          <strong>Employé:</strong>
          <div className='employe-rdv'>
            {employe}
          </div>
      </div>
      
      <div className='bloc-btn-rdv'>
          <div className='btn-action-info-rdv'>
            <button onClick={handleClickFicheClient}>Voir la fiche client</button>
          </div>
        
        <div className='btn-action-info-rdv'>
          <button onClick={handleClickModifierRdv}>Modifier le RDV</button>
        </div>
      </div>
    
    </div>
  );
}

export default InfoRdv;



