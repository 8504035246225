import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './gestionPrestations.css';
import { useMonContexte } from '../MonContext';
import { useNavigate } from 'react-router-dom';
import InfoPrestationVide from './InfoPrestationVide';
import InfoPrestation from './InfoPrestation';
import SaisirPrestation from './SaisirPrestation';
import GestionPrestations from './GestionPrestations';

const RestaurePresta = () => {

    const {adresseServeurBackEnd, fichePersonnel, setPageConnexion, setContenuPageDroite, fichePrestaSelect, 
                setContenuPageCentrale, setFichePrestaSelect} =  useMonContexte();
  
      const [prestations, setPrestations] = useState([]);
      const navigate = useNavigate();
      const [error, setError] = useState('');

      const fetchPrestationsARestaurer = async () => {
        try {
          const response = await axios.get(`${adresseServeurBackEnd}/listePrestationsSuspendu`,
          {headers:{ Authorization: `Bearer ${fichePersonnel.token}`}
          });
          if (Array.isArray(response.data)) {
            setPrestations(response.data);
          } else {
            setError("Il n'y a pas de prestation suspendu");
            console.error('La réponse de l\'API n\'est pas un tableau', response.data);
          }
        } catch (error) {
          if (error.response && error.response.status === 403) {
            console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
            navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
            setPageConnexion(false)
          } else {
            setError('Erreur lors du chargement des prestations');
            console.error('Erreur lors du chargement des prestations', error);
          }
        }
      };
    
      useEffect(() => {
        fetchPrestationsARestaurer();
        setContenuPageDroite(<InfoPrestationVide />)
      }, []);


      const handleRestaurePrestations = async(prestation) =>{        
        try {
            await axios.put(`${adresseServeurBackEnd}/restaurePrestationSuspendu/${prestation.id}`,{}, {  
                headers: {
                    Authorization: `Bearer ${fichePersonnel.token}`,  // Ajout du token
                    'Content-Type': 'application/json'  // En-tête correct pour JSON
                }
            });            
            setContenuPageDroite(<InfoPrestationVide />)
            setContenuPageCentrale(<GestionPrestations/>);  
        } catch (error) {
            if (error.response && error.response.status === 403) {
                console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
                navigate('/gestion');  // Redirection en cas de token expiré ou invalide
                setPageConnexion(false);
            } else {
                setError('Erreur lors de la suspension de la prestation');
                console.error('Erreur lors de la suspension de la prestation', error);
            }
        }
      }

  return (
    <div className="prestations-manager">
        <h1>Restauration des Prestations</h1>
          {prestations.length === 0 ? 
            <div className='pas-presta'>
                <p>Pas de Prestation à restaurer</p>
                <button onClick={() => setContenuPageCentrale(<GestionPrestations/>)}>Annuler</button>
            </div>
          
          
          
          :
                    
            <div className='container-prestations-table'>
            <table className="prestations-table">
                <thead>
                    <tr>
                    <th>Nom</th>
                    <th>Description</th>
                    <th>Durée </th>
                    <th>Prix </th>
                    <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {prestations.map((prestation) => (
                    <tr key={prestation.id}>
                        <td>{prestation.nom}</td>
                        <td>{prestation.description}</td>
                        <td>{prestation.duree} min</td>
                        <td>{prestation.prix} €</td>
                        <td className="action-button">
                            <button  onClick={() => handleRestaurePrestations(prestation)}>Rétablir</button>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </table>
            </div> }
    </div>
  );
}

export default RestaurePresta