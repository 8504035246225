
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './gestionClient.css';
import { useMonContexte } from '../MonContext';
import { useNavigate } from 'react-router-dom';
import AfficheClientVide from './AfficheClientVide';
import GestionClients from './GestionClients';

const RestaurerFicheClient = () => {

    const {adresseServeurBackEnd, fichePersonnel, setFicheClient, setContenuPageDroite, setContenuPageCentrale,
            setPageConnexion} =  useMonContexte();
  
      const [clientsSupprimes, setClientsSupprimes] = useState([]);
      const [error, setError] = useState('');
      const navigate = useNavigate();

      const fetchClientsSupprime = async () => {
        try {
          const response = await axios.get(`${adresseServeurBackEnd}/clientsSupprime`,
            {headers:{ Authorization: `Bearer ${fichePersonnel.token}`}
          });
          if (Array.isArray(response.data)) {
            setClientsSupprimes(response.data);
          } else {
            setError('La réponse de l\'API n\'est pas un tableau');
            console.error('La réponse de l\'API n\'est pas un tableau', response.data);
          }
        } catch (error) {
          if (error.response && error.response.status === 403) {
            console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
            navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
            setPageConnexion(false)
          } else {
            setError('Erreur lors du chargement des clients');
            console.error('Erreur lors du chargement des clients', error);
          }
        }
      };

    const handleRestaureFicheClient = async(client) =>{
        console.log(client)
        try {
            const response = await axios.put(`${adresseServeurBackEnd}/restaureClientsSupprime/${client.id}`,{},
              {headers:{ Authorization: `Bearer ${fichePersonnel.token}`}
            });
            setContenuPageCentrale(<GestionClients/>);          
        } catch (error) {
            if (error.response && error.response.status === 403) {
              console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
              navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
              setPageConnexion(false)
            } else {
              setError('Erreur lors du chargement des clients');
              console.error('Erreur lors du chargement des clients', error);
            }
        }
    }


    useEffect(() => {
        fetchClientsSupprime();    
        setContenuPageDroite(<AfficheClientVide/>);
    }, []);
    

  return (<div className="clients-manager">
    <h1>Restaurer des Fiches clients supprimées</h1>
    {error && <p className="error">{error}</p>}
    

     {clientsSupprimes.length === 0 ? (
          <p>Pas de fiche client à restaurer</p>
        ) : (<div className='table-container-client'>
    <table className="clients-table">
      <thead>
        <tr>
          <th>Nom</th>
          <th>Prénom</th>
          <th>Date de Naissance</th>
          <th>Téléphone</th>
          <th>Adresse</th>
          <th>Code Postal</th>
          <th>Ville</th>
          <th>Email</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {clientsSupprimes
          .sort((a, b) => a.nom.localeCompare(b.nom))
          .map((client) => (
            <tr key={client.id}>
              <td>{client.nom}</td>
              <td>{client.prenom}</td>
              <td>{new Date(client.dateNaissance).toLocaleDateString('fr-FR')}</td>
              <td>{client.telephone}</td>
              <td>{client.adresse}</td>
              <td>{client.codePostal}</td>
              <td>{client.ville}</td>
              <td>{client.mail}</td>
              <td className='btn-table-client' >
                <button className="edit-button" onClick={() => handleRestaureFicheClient(client)}>Restaurée</button>
              </td>
            </tr>
        ))}
      </tbody>
    </table>
  </div>)}
  </div>
);
}

export default RestaurerFicheClient