import React, { useState } from 'react'
import { useMonContexte } from '../MonContext';
import { useNavigate } from 'react-router-dom';
import GestionPrestations from './GestionPrestations';
import axios from 'axios';

const SuspendrePresta = () => {

    const {adresseServeurBackEnd, contenuPageDroite, setContenuPageDroite, setContenuPageCentrale, 
                fichePersonnel, setPageConnexion, fichePrestaSelect, setFichePrestaSelect} =  useMonContexte();

    const navigate = useNavigate();
    const [error, setError] = useState('');
    
    const handleSuspendre = async () => {
        
        try {
            await axios.put(`${adresseServeurBackEnd}/suspendrePrestations/${fichePrestaSelect.id}`,{}, {  
                headers: {
                    Authorization: `Bearer ${fichePersonnel.token}`,  // Ajout du token
                    'Content-Type': 'application/json'  // En-tête correct pour JSON
                }
            });
            setContenuPageCentrale(<GestionPrestations />);  // Mise à jour de l'affichage
        } catch (error) {
            if (error.response && error.response.status === 403) {
                console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
                navigate('/gestion');  // Redirection en cas de token expiré ou invalide
                setPageConnexion(false);
            } else {
                setError('Erreur lors de la suspension de la prestation');
                console.error('Erreur lors de la suspension de la prestation', error);
            }
        }
    };
  return (
    <div className='container-suppression-client'>
      <div className='titre-suppression-client'>
          <p>Voulez-vous suspendre : </p>
          <strong>{fichePrestaSelect.nom} <br/>  {fichePrestaSelect.description}</strong>
      </div>
  
      <div className='avertissement-suppression-client'>
          <div className='text-danger-supp-client'>
              <p>Vous pourrez par la suite, à tout moment, récupérer la prestation <strong>{fichePrestaSelect.nom}</strong> </p>             
          </div>
      </div>
      <div className='btn-suppression-client'>
        <button onClick={() => setContenuPageCentrale(<GestionPrestations/>)} > Annuler</button>          
        <button onClick={() => handleSuspendre()}> Suspendre</button>
      </div>
  </div>
  )
}

export default SuspendrePresta