import React, { useState } from 'react';
import './supprimerClient.css';
import { useMonContexte } from '../MonContext';
import imageDanger from '../image/icons8-danger-80.png';
import { useNavigate } from 'react-router-dom';
import GestionClients from './GestionClients';
import axios from 'axios';
import AfficheClientVide from './AfficheClientVide';

const SupprimerClient = () => {
    const {adresseServeurBackEnd, fichePersonnel, setPageConnexion, setContenuPageDroite, setContenuPageCentrale, ficheClient} =  useMonContexte();

    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleDelete = async() =>{
        try {
            const response = await axios.delete(`${adresseServeurBackEnd}/clients/${ficheClient.id}`,
              {headers:{ Authorization: `Bearer ${fichePersonnel.token}`}
            });
            console.log(response.data)
            setContenuPageCentrale(<GestionClients/>);
            setContenuPageDroite(<AfficheClientVide/>);
          } catch (error) {
            if (error.response && error.response.status === 403) {
              console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
              navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
              setPageConnexion(false)
            } else {
              setError('Erreur lors du chargement des clients');
              console.error('Erreur lors du chargement des clients', error);
            }
          }
    }

  return (
    <div className='container-suppression-client'>
        <div className='titre-suppression-client'>
            <p>Voulez-vous supprimer la fiche de: </p>
            <strong>{ficheClient.nom} {ficheClient.prenom}</strong>
        </div>

        <div className='avertissement-suppression-client'>
            <div className='icone-danger-supp-client'>
                <img src={imageDanger} alt='imgedanger'/>
            </div>
            <div className='text-danger-supp-client'>
                <p>Attention la suppression de la fiche de <strong>{ficheClient.prenom}</strong> sera définitive!</p>
                <p>Toutes les données qui y sont attachées seront supprimées! </p>
            </div>
        </div>
        <div className='btn-suppression-client'>
          <button onClick={() => setContenuPageCentrale(<GestionClients/>)} > Annuler</button>          
          <button onClick={() => handleDelete()}> Supprimer</button>
        </div>
    </div>
  )
}

export default SupprimerClient