import React, { useState, useEffect } from 'react';


const SaisieObjectifs = ({ employe, onObjectifChange }) => {
    const [objectifs, setObjectifs] = useState(employe.objectifs);
    const [objectifMensuel, setObjectifMensuel] = useState(employe.objectifMensuel);

    useEffect(() => {
        // Met à jour les objectifs lorsque les données de l'employé changent
        setObjectifs(employe.objectifs);
        setObjectifMensuel(employe.objectifMensuel);
    }, [employe]);

    // Gérer le changement de valeur dans l'input
    const handleChangeObjectif = (index, e) => {
    const newObjectifs = objectifs.map((objectif, i) => {
      if (i === index) {
        return {
          ...objectif,
          objectifHebdo: parseFloat(e.target.value) || 0 // Défaut à 0 si la valeur est vide
        };
      }
      return objectif;
    });

    const newObjectifMensuel = calculateTotal(newObjectifs.map(o => o.objectifHebdo));
    console.log('newObjectifs -> Saisi: ',newObjectifs)
    setObjectifs(newObjectifs);
    setObjectifMensuel(newObjectifMensuel);
    onObjectifChange(employe.employeId, newObjectifs); // Notifie le parent de la mise à jour
  };

  const calculateTotal = (valeurs) => {
    return valeurs.reduce((total, valeur) => total + parseFloat(valeur || 0), 0);
  };

  
    return (
        <tr key={employe.employeId}>
            <td>{employe.nom} {employe.prenom}</td>
            {objectifs.map((semaine, index) => (
                <td key={index}>
                    <input
                        type="number"
                        step="0.01"
                        value={semaine.objectifHebdo || ''}
                        onChange={(e) => handleChangeObjectif(index, e)}
                    />
                </td>
            ))}
            <td>{objectifMensuel || 0} €</td>
        </tr>
    );
};


export default SaisieObjectifs 

   