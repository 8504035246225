import React from 'react';
import './infoPrestation.css';

const InfoPrestationVide = () => {




  return (
    
    <div className='bloc-affich-info-presta'>
         <div className='text-presta-menu'>
            Prestation
        </div>
        <div className='bloc-affich-info-presta'>                
                <div className='champ-affichage-info-presta'>
                    Nom: <span> - </span>
                </div>
                <div className='champ-affichage-info-presta'>
                    Prix: <span> - </span>
                </div>
                <div className='champ-affichage-info-presta'>
                    durée:<span> - </span> 
                </div>
        </div>
        
        <div className='info-presta-vide'> 
            Veuillez sélectionner une prestation.
            
        </div>
         
    </div> 
   
  )
}


export default InfoPrestationVide