
import React, { useState} from 'react';
import { useMonContexte } from '../MonContext';
import './selectClientRdv.css'
import SelectNewClient from './SelectNewClient';
import PriseRDVClient from './PriseRDVClient';
import SelectBaseDonnee from './selectBaseDonnee';

const SelectClientRdv = () => {
    const { adresseServeurBackEnd, fichePersonnel, setFicheClient, ficheClient, setPageConnexion, setContenuPageCentrale, 
            setContenuPageDroite } = useMonContexte();


        
    const fonctionPriseRdv = () => { 
        setGestionComposant(<PriseRDVClient />)
    }
    const fonctionSaisieClient = () => {       
        setGestionComposant(<SelectNewClient fonctionPriseRdv={fonctionPriseRdv} fonctionSelectClientdB={fonctionSelectClientdB}  />)
    }
    const fonctionSelectClientdB = () => {       
        setGestionComposant(<SelectBaseDonnee fonctionSaisieClient={fonctionSaisieClient} fonctionPriseRdv={fonctionPriseRdv}/>)
    }


    const [gestionComposant, setGestionComposant] = useState(<SelectBaseDonnee fonctionSaisieClient={fonctionSaisieClient} 
                    fonctionPriseRdv={fonctionPriseRdv} />);


    return (
        <div className='container-select-client'>
         {gestionComposant}
        </div>
    );
};

export default SelectClientRdv;
