import React, { useEffect, useState } from 'react'
import './gestionPrestations.css'
import { useNavigate } from 'react-router-dom';
import { useMonContexte } from '../MonContext';
import InfoPrestation from './InfoPrestation';
import axios from 'axios';
import GestionPrestations from './GestionPrestations';

const SaisirPrestation = ({id}) => {

    const {adresseServeurBackEnd, fichePersonnel, setPageConnexion, setContenuPageCentrale, setContenuPageDroite, 
              fichePrestaSelect, setFichePrestaSelect} =  useMonContexte();
  
      const [form, setForm] = useState({
          id: null,
          nom: '',
          description: '',
          duree: '',
          prix: ''
        });

      const navigate = useNavigate();
      const [error, setError] = useState('');
    
      const choixModeSaisi = () => {
        if (id) {
            setForm(fichePrestaSelect);
        } else {
            setForm({
                id: null,
                nom: '',
                description: '',
                duree: '',
                prix: ''
            });
        }
      }


      useEffect(() => {
        choixModeSaisi();
      }, []);
    
         

    
    const handleChange = (e) => {
        setError('');
        const { name, value } = e.target;
        setForm((prevForm) => ({ ...prevForm, [name]: value }));
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          if (form.id) {
            await axios.put(`${adresseServeurBackEnd}/prestations/${form.id}`, form, {
              headers: {
                  Authorization: `Bearer ${fichePersonnel.token}`,
                  'Content-Type': 'application/json'
              }
            });
          } else {
            await axios.post(`${adresseServeurBackEnd}/prestations`, form, {
              headers: {
                  Authorization: `Bearer ${fichePersonnel.token}`,
                  'Content-Type': 'application/json'
              }
            });
          }
          setForm({ id: null, nom: '', description: '', duree: '', prix: '' });
          setContenuPageCentrale(<GestionPrestations/>)
        } catch (error) {
          if (error.response && error.response.status === 403) {
            console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
            navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
            setPageConnexion(false)
          } else {
            setError('Erreur lors de l\'enregistrement de la prestation');
            console.error('Erreur lors de l\'enregistrement de la prestation', error);
          }
        }
      };
    
    
      
  return (
    <div className="prestations-manager">
        {id?
          <h1>Modification d'une Prestation</h1>:
          <h1>Creation d'une Prestation</h1>}
            {error && <p className="error">{error}</p>}
            
          <div className='container-prestations-table'></div>
        <form className="prestations-form" onSubmit={handleSubmit}>
        <label>Nom de la prestation:</label>
            <input
                type="text"
                name="nom"
                placeholder="Nom"
                value={form.nom}
                onChange={handleChange}
                required
            />
            <label>Description:</label>
            <textarea
                name="description"
                placeholder="Description"
                value={form.description}
                onChange={handleChange}
            ></textarea>
            <label>Durée en min:</label>
            <input
                type="number"
                name="duree"
                placeholder="Durée (minutes)"
                value={form.duree}
                onChange={handleChange}
                required
            />
            <label>Prix en €:</label>
            <input
                type="number"
                step="0.01"
                name="prix"
                placeholder="Prix (€)"
                value={form.prix}
                onChange={handleChange}
                required
            />
            <div className='btn-gestion-prestation'>
                <button type="submit">{id ? 'Modifier' : 'Ajouter'}</button>
                <button onClick={()=> setContenuPageCentrale(<GestionPrestations/>)} >Annuler</button>
            </div>
            </form>
    </div>
  )
}

export default SaisirPrestation