
import React, { useState } from 'react';
import axios from 'axios';
import './searchClient.css';
import { useMonContexte } from '../MonContext';
import FicheClient from '../Client/FicheClient';
import AffichageClientSelectionnee from '../PageTemplete/AffichageClientSelectionnee';
import useWindowSize from '../UseWindowSize';
import { useNavigate } from 'react-router-dom';

const SearchClient = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [ficheTempo, setFicheTempo] = useState([]);

    const { adresseServeurBackEnd, setFicheClient, setContenuPageCentrale, setContenuPageDroite, fichePersonnel, setPageConnexion } = useMonContexte();
    const navigate = useNavigate();

    const handleChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const { width, height } = useWindowSize(); // Si non utilisé, peux être retiré

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(searchTerm)
        try {
            const response = await axios.get(`${adresseServeurBackEnd}/clientsSearch?term=${encodeURIComponent(searchTerm)}`, {
                headers: { Authorization: `Bearer ${fichePersonnel.token}` }
            });
            console.log(`${adresseServeurBackEnd}/clients/search?term=${encodeURIComponent(searchTerm)}`)
            setFicheTempo(response.data);
            console.log(response.data)
        } catch (error) {
            if (error.response && error.response.status === 403) {
                console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
                navigate('/gestion'); // Redirection si token expiré ou invalide
                setPageConnexion(false);
            } else {
                console.error('Erreur lors de la recherche de clients:', error);
                alert('Une erreur est survenue lors de la recherche des clients.');
            }
        }
    };

    const handleSelectionClient = (client) => {
        setFicheClient(client);
        setContenuPageCentrale(<FicheClient />);
        setContenuPageDroite(<AffichageClientSelectionnee ficheClient={client} />);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return 'Date invalide';
        }
        return date.toLocaleDateString('fr-FR');
    };

    return (
        <div className="client-search-search">
            <div className="bloc-form-search">
                <form className="form-search" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Rechercher par nom, date de naissance, téléphone ou email"
                        value={searchTerm}
                        onChange={handleChange}
                    />
                    <button type="submit">Rechercher</button>
                </form>
            </div>
            <div className="table-container">
                <table className="clients-table-search">
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Date de Naissance</th>
                            <th>Téléphone</th>
                            <th>Email</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ficheTempo.map((client) => (
                            <tr key={client.id}>
                                <td>{client.nom}</td>
                                <td>{client.prenom}</td>
                                <td>{formatDate(client.dateNaissance)}</td> {/* Utilisation de la fonction de formatage */}
                                <td>{client.telephone}</td>
                                <td>{client.mail}</td>
                                <td>
                                    <button className="btn-select-search" onClick={() => handleSelectionClient(client)}>
                                        Sélectionner
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SearchClient;

/*
import React, { useState } from 'react';
import axios from 'axios';
import './searchClient.css';
import { useMonContexte } from '../MonContext';
import FicheClient from '../Client/FicheClient';
import AffichageClientSelectionnee from '../PageTemplete/AffichageClientSelectionnee';
import useWindowSize from '../UseWindowSize'
import { useNavigate } from 'react-router-dom';

const SearchClient = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [ficheTempo, setFicheTempo] = useState([]);

    const { adresseServeurBackEnd, setFicheClient, setContenuPageCentrale, setContenuPageDroite, fichePersonnel,
            setPageConnexion } = useMonContexte();
    const navigate = useNavigate();

    const handleChange = (e) => {
      setSearchTerm(e.target.value);
      console.log(e.target.value)
    };

    const {width, height} = useWindowSize();

    const handleSubmit = async (e) => {
      console.log('response.data');
        e.preventDefault();
        try {
            const response = await axios.get(`${adresseServeurBackEnd}/clients/search?term=${encodeURIComponent(searchTerm)}`,
                { headers:{Authorization: `Bearer ${fichePersonnel.token}`}
            });            
            setFicheTempo(response.data);
            console.log(response.data)
        } catch (error) {
            if (error.response && error.response.status === 403) {
              console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
              navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
              setPageConnexion(false)
            } else {
                console.log('response.data');
                console.error('Error searching clients:', error);
                alert('An error occurred while searching for clients.');
            }
        }
    };

    const handleSelectionClient = (client) => {
        setFicheClient(client);
        setContenuPageCentrale(<FicheClient/>);
        setContenuPageDroite(<AffichageClientSelectionnee ficheClient={client}/> )
    };

    return (
        <div className="client-search-search">
            <div className='bloc-form-search'>
                <form className='form-search' onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Rechercher par nom, date de naissance, téléphone ou email"
                        value={searchTerm}
                        onChange={handleChange}
                    />
                    <button type="submit">Rechercher</button>
                </form>
            </div>
            <div className="table-container">
                <table className="clients-table-search">
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Date de Naissance</th>
                            <th>Téléphone</th>
                            <th>Email</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ficheTempo.map((client) => (
                            <tr key={client.id}>
                                <td>{client.nom}</td>
                                <td>{client.prenom}</td>
                                <td>{new Date(client.dateNaissance).toLocaleDateString('fr-FR')}</td>
                                <td>{client.telephone}</td>
                                <td>{client.mail}</td>
                                <td>
                                    <button className="btn-select-search" onClick={() => handleSelectionClient(client)}>Sélectionner</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SearchClient;

*/
