import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './memoClient.css';
import { useMonContexte } from '../MonContext';
import { useNavigate } from 'react-router-dom';

const MemoClient = () => {
    const {adresseServeurBackEnd, fichePersonnel, ficheClient, setPageConnexion} =  useMonContexte();

    const [memos, setMemos] = useState([]);
    const [newMemoText, setNewMemoText] = useState('');
    const navigate = useNavigate();

    const fetchMemos = async () => {
        try {
          const response = await axios.get(`${adresseServeurBackEnd}/memoClient/${ficheClient.id}`, {
            headers: { Authorization: `Bearer ${fichePersonnel.token}` }
            });
          setMemos(response.data);
          console.log(response.data)
        } catch (error) {
          if (error.response && error.response.status === 403) {
            console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
            navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
            setPageConnexion(false)
          } else {
             console.error('Erreur lors de la récupération des mémos:', error);
          }
        }
      };

    useEffect(() => {
        fetchMemos();
    }, [adresseServeurBackEnd]);  

    const handleAddMemo = async () => {
        try {
          await axios.post(`${adresseServeurBackEnd}/memoClient`, {
            clientId: ficheClient.id, 
            employeId: fichePersonnel.id, 
            memoText: newMemoText,
            }, {
              headers: {
                Authorization: `Bearer ${fichePersonnel.token}`,
                'Content-Type': 'application/json'
              }
          });
          setNewMemoText('');
          fetchMemos();
        } catch (error) {
          if (error.response && error.response.status === 403) {
            console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
            navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
            setPageConnexion(false)
          } else {
              console.error('Erreur lors de l\'ajout du mémo:', error);
          }
        }
      };
   


  return (
    <div className='memo-manager'>    
      <div className='add-memo'>
        <textarea
          value={newMemoText}
          onChange={(e) => setNewMemoText(e.target.value)}
          placeholder="Écrivez un nouveau mémo..."
        />
        <button onClick={handleAddMemo}>Ajouter</button>
      </div>
     <div className="memos-list">
              {memos.map((memo) => (
                <div key={memo.id} className='div-memo-map' > 
                    <div className='memo-item'>                      
                        <p className='prompt-memo-client' >Le {new Date(memo.dateHeureCreation).toLocaleDateString('fr-FR')} &nbsp;
                          {memo.employePrenom} a écrit: </p>
                        <p>{memo.memoText}</p>            
                    </div>
                  </div>  
                
              ))}
        </div>     
   
    </div>
  )
}

export default MemoClient