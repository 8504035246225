import React from 'react'
import './footerDesk.css'
import useWindowSize from '../UseWindowSize';
 

const FooterDesk = () => { 
    const size = useWindowSize()
    return (   
        <div className='contenu-footer-desk'>
            <p>
                    Ô pays des Nail, l'élégance jusqu'au bout des ongles  
            </p> 
        </div>
    )
}

export default FooterDesk