import React from 'react'
import { useMonContexte } from '../MonContext';
import './informationPersonnel.css';

const InfoPersonnelVide = () => {


    const {adresseServeurBackEnd, contenuPageDroite, setContenuPageDroite,setContenuPageCentrale, 
                fichePersonnel, setPageConnexion, fichePersonnelSelect, setFichePersonnelSelect} =  useMonContexte();


  return (
    
    <div className="bloc-affich-personnel">
         <div className='text-fonction-menu'>
            Personnel:
        </div>
        <div className='bloc-fiche-ident'>                
                <div className='champ-affichage-selection-client'>
                    Nom: <span>-</span>
                </div>
                <div className='champ-affichage-selection-client'>
                    Prénom: <span>-</span>
                </div>
                <div className='champ-affichage-selection-client'>
                    Fonction:<span>-</span> 
                </div>
        </div>
        <div className='bloc-btn-gestion-personnel'> 
            <div className='btn-affichage-selection-personnel'>
                 <p>Veuillez Sélectionner un Client</p>
            </div>
        </div>
         
    </div> 
  )
}

export default InfoPersonnelVide