import React from 'react'
import PresentationDesk from '../component/PresentationDesk'
import ServiceDesk from '../component/ServiceDesk'
import GalleryDesk from '../component/GalleryDesk'
import ContactDesk from '../component/ContactDesk'
import AboutDesk from '../component/AboutDesk'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import FooterPhone from '../component/FooterPhone'
import NavBarPhone from '../component/NavBarPhone'
import './appWebPhone.css'
import PresentationPhone from '../component/PresentationPhone'

const AppWebPhone = () => {
  return (
    <>
      <BrowserRouter>
      <div className="app-container">
        <NavBarPhone />
            <div className="content">
                <Routes>       
                    <Route path='/' element={<PresentationPhone/>} />
                    <Route path='/service' element={<ServiceDesk/>} />
                    <Route path='/gallery' element={<GalleryDesk/>}/>
                    <Route path='/about' element={<AboutDesk/>} />   
                    <Route path='/contact' element={<ContactDesk/>} /> 
                </Routes>
            </div>
            <div className='app-footer'>
        <FooterPhone/>
      </div> 
      </div>
    </BrowserRouter> 
    </>
  )
}

export default AppWebPhone