import React from 'react';
import './infoObjectifVide.css';

const InfoObjectifVide = () => {
  return (
    
    <div className='bloc-affich-info-presta'>
        <div className='text-presta-menu'>
            Objectifs
        </div>
        <div className='bloc-affich-info-presta'>                
                <div className='champ-affichage-info-presta'>
                    Nom: <span> - </span>
                </div>
                <div className='champ-affichage-info-presta'>
                    Prenom: <span> - </span>
                </div>
                <div className='champ-affichage-info-presta'>
                    Fonction:<span> - </span> 
                </div>
        </div>
        
        <div className='info-presta-vide'> 
           Vous pouvez modifier les objectifs de vos colaborateurs
            
        </div>
         
    </div> 
  )
}

export default InfoObjectifVide