import React from 'react'
import { useMonContexte } from '../MonContext';
import './affichageClientSelection.css';
import Historique from '../Historique/Historique';
import PriseRDVClient from '../RendezVous/PriseRDVClient';
import ModifMemoClient from '../Memo/ModifMemoClient';
import QrCode from '../QrCode/QrCode';
import PhotoClient from '../Photo/PhotoClient';
import SupprimerClient from '../Client/SupprimerClient';
import ModifAjoutClient from '../Client/ModifAjoutClient';

const AffichageClientSelectionnee = () => {


    const {setContenuPageCentrale, ficheClient} =  useMonContexte();


  return (
    <div className='container-menu-client'>
    {ficheClient && <div className="bloc-affich-client">
         <div className='text-fonction-menu'>
            Cliente:
        </div>
        <div className='bloc-fiche-ident'>                
                <div className='champ-affichage-selection-client'>
                    Nom: <span>{ficheClient.nom}</span>
                </div>
                <div className='champ-affichage-selection-client'>
                    Prénom: <span>{ficheClient.prenom}</span>
                </div>
                <div className='champ-affichage-selection-client'>
                    Téléphone:<span>{ficheClient.telephone}</span> 
                </div>
        </div>
        <div className='bloc-btn-gestion-client'>    
            <div className='btn-affichage-selection-client'>
                <button onClick={()=>setContenuPageCentrale(<ModifAjoutClient id={ficheClient.id} />)}>Modifier la Fiche </button>
            </div>
            <div className='btn-affichage-selection-client'>
                <button onClick={()=>setContenuPageCentrale(<Historique/>)}>Enregistrer une Prestation</button>
            </div>           
            <div className='btn-affichage-selection-client'>
                <button onClick={()=>setContenuPageCentrale(<PhotoClient/>)}>Les photos</button>
            </div>
            <div className='btn-affichage-selection-client'>
                <button onClick={()=>setContenuPageCentrale(<ModifMemoClient/>)}>Modifier les Memos</button>
            </div>
            <div className='btn-affichage-selection-client'>
                <button onClick={()=>setContenuPageCentrale(<PriseRDVClient/>)}>Prendre un RDV</button>
            </div>
            <div className='btn-affichage-selection-client'>
                <button onClick={()=>setContenuPageCentrale(<SupprimerClient />)}>Supprimer la Fiche </button> 
            </div>
            <QrCode/>
        </div>
    </div>} 
    </div>
  )
}

export default AffichageClientSelectionnee