import React, { useEffect } from 'react';
import axios from 'axios';
import './memoClient.css';
import { useMonContexte } from '../MonContext';
import { Navigate, useNavigate } from 'react-router-dom';

const AfficheMemo = () => {
    const {adresseServeurBackEnd, fichePersonnel, ficheClient, memos, setMemos, setPageConnexion} =  useMonContexte();

    const navigate = useNavigate();

    const fetchMemos = async () => {
        try {
          const response = await axios.get(`${adresseServeurBackEnd}/memoClient/${ficheClient.id}`,{
            headers:{Authorization: `Bearer ${fichePersonnel.token}`}
          }); 
          setMemos(response.data);
        } catch (error) {
          if (error.response && error.response.status === 403) {
            console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
            navigate('/gestion');  // Redirige vers la page de connexion en cas de token expiré ou invalide
            setPageConnexion(false)
          } else {
              console.error('Erreur lors de la récupération des mémos:', error);
          }
        }
    };

    useEffect(() => {
        fetchMemos();
    }, [adresseServeurBackEnd]);  


  return (
    <div className='memo-affich'>  
        <div className="memos-list">
              {memos.map((memo) => (
                <div key={memo.id} className='div-memo-map' > 
                    <div key={memo.id} className='memo-item'>                      
                        <p className='prompt-memo-client' >Le {new Date(memo.dateHeureCreation).toLocaleDateString('fr-FR')} &nbsp;
                          {memo.employePrenom} a écrit: </p>
                        <p>{memo.memoText}</p>            
                    </div>
                  </div>  
                
              ))}
        </div> 
    </div>
  )
}

export default AfficheMemo