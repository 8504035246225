import React from 'react';
import './infoPrestation.css'
import { useMonContexte } from '../MonContext';
import SaisirPrestation from './SaisirPrestation';
import SupprimerPrestation from './SupprimerPrestation';
import SuspendrePresta from './SuspendrePresta';
import RestaurePresta from './RestaurePresta';

const InfoPrestation = () => {


    const {adresseServeurBackEnd, contenuPageDroite, setContenuPageDroite, setContenuPageCentrale, 
                fichePersonnel, setPageConnexion, fichePrestaSelect, setFichePrestaSelect} =  useMonContexte();


  return (
    
    <div className='bloc-affich-info-presta'>
         <div className='text-presta-menu'>
            Prestation
        </div>
        <div className='bloc-affich-info-presta'>                
                <div className='champ-affichage-info-presta'>
                    Nom: <span>{fichePrestaSelect.nom}</span>
                </div>
                <div className='champ-affichage-info-presta'>
                    Prix: <span>{fichePrestaSelect.prix} €</span>
                </div>
                <div className='champ-affichage-info-presta'>
                    durée:<span>{fichePrestaSelect.duree} min</span> 
                </div>
        </div>
        
        <div className='bloc-btn-menu-presta'> 
           
            <div className='btn-menu-presta'>
                <button onClick={()=>setContenuPageCentrale(<SaisirPrestation id={fichePrestaSelect.id}/>)}>Modifier la Prestation</button>
            </div>
            <div className='btn-menu-presta'>
                <button onClick={()=>setContenuPageCentrale(<SuspendrePresta/>)}>Suspendre la Prestation</button>
            </div>
            <div className='btn-menu-presta'>
                <button onClick={()=>setContenuPageCentrale(<SupprimerPrestation/>)} >Supprimer la Prestation</button>
            </div>
            
        </div>
         
    </div> 
   
  )
}

export default InfoPrestation