import React from 'react'
import { useMonContexte } from '../MonContext';
import './infoGestionPersonnel.css';

const InfoGestionPersonnel = () => {


    const {adresseServeurBackEnd, contenuPageDroite, setContenuPageDroite,setContenuPageCentrale, 
                fichePersonnel, setPageConnexion, fichePersonnelSelect, setFichePersonnelSelect} =  useMonContexte();


  return (
    
    <div className="bloc-affich-personnel">
         <div className='text-fonction-menu'>
            Personnel:
        </div>
        <div className='bloc-fiche-ident'>                
                <div className='champ-affichage-selection-client'>
                    Nom: <span>{fichePersonnelSelect.nom}</span>
                </div>
                <div className='champ-affichage-selection-client'>
                    Prénom: <span>{fichePersonnelSelect.prenom}</span>
                </div>
                <div className='champ-affichage-selection-client'>
                    Fonction:<span>{fichePersonnelSelect.fonction}</span> 
                </div>
        </div>
        <div className='bloc-btn-gestion-personnel'> 
            <div className='btn-affichage-selection-personnel'>
                <button onClick={()=>setContenuPageCentrale()}> Modifier les horaires</button>
            </div>
            <div className='btn-affichage-selection-personnel'>
                <button onClick={()=>setContenuPageCentrale()}>Modifier Fonction</button>
            </div>
            <div className='btn-affichage-selection-personnel'>
                <button onClick={()=>setContenuPageCentrale()} >Gestion EDT</button>
            </div>
            <div className='btn-affichage-selection-personnel'>
                <button onClick={()=>setContenuPageCentrale()}>Ajouter une nouvelle fonction</button>
            </div>
            <div className='btn-affichage-selection-personnel'>
                <button onClick={()=>setContenuPageCentrale()}>Encours</button>
            </div>
            <div className='btn-affichage-selection-personnel'>
                <button onClick={()=>setContenuPageCentrale()}>Supprimer Personnel</button>
            </div>
        </div>
         
    </div> 
   
  )
}

export default InfoGestionPersonnel