import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useMonContexte } from '../MonContext';
import Calendrier from '../Calendrier/Calendrier';
import GestionPrestations from '../Prestation/GestionPrestations';
import GestionEmployes from '../Personnel/GestionEmployes';
import GestionClients from '../Client/GestionClients';
import SearchClient from '../Client/SearchClient';
import GestionObjectifs from '../Objectifs/GestionObjectifs';
import SaisieRendezVous from '../RendezVous/SaisieRendezVous';
import logojnp from '../image/logojnp974_bleu.png';
import './menuFonction.css';
import useWindowSize from '../UseWindowSize';
import { useNavigate } from 'react-router-dom';
import Header from '../Connexion/Header';
import SelectClientRdv from '../RendezVous/SelectClientRdv';

const MenuFonction = () => {
    const {adresseServeurBackEnd, fichePersonnel, setContenuPageDroite, setContenuPageCentrale, setPageConnexion } = useMonContexte();

    const [tableMenu, setTableMenu] = useState({});

    function retourneVraieFaux (value) {
        if (value) {
            return true            
        } else {
            return false
        }
    }

    useEffect(() => {
        const fetchMenuPersonnel = () =>{
            setTableMenu(fichePersonnel.permissions)
        }
        fetchMenuPersonnel();
    }, [fichePersonnel]);

    return (
        <div className="nav-side-gauche">
            <div className='bloc-menu-side-gauche'>
                {retourneVraieFaux(tableMenu.client) && <fieldset>
                    <legend>Clients</legend>
                    <div className='btn-affichage-menu-fonction'>
                        <button onClick={() => setContenuPageCentrale(<GestionClients />)}>Gestion des Clients</button>
                        <button onClick={() => setContenuPageCentrale(<SearchClient />)}>Recherche un Client</button>
                    </div>

                </fieldset>}

                {retourneVraieFaux(tableMenu.rendezVous) && <fieldset>
                    <legend>RDV</legend>
                    <div className='btn-affichage-menu-fonction'>
                        <button onClick={() => {setContenuPageCentrale(<Calendrier />);setContenuPageDroite(<Header/>)}}>Calendrier</button>
                        <button  onClick={() => setContenuPageCentrale(<SelectClientRdv/>)}>Prendre RDV</button></div>
                </fieldset>}

                {retourneVraieFaux(tableMenu.objectifs) && <fieldset>
                    <legend>Objectifs</legend>
                    <div className='btn-affichage-menu-fonction'>
                        <button  onClick={() => setContenuPageCentrale(<GestionObjectifs />)}>Gestion des objectifs</button>
                        </div>
                </fieldset>}

                {retourneVraieFaux(tableMenu.prestations) && <fieldset>
                    <legend>Prestations</legend>
                    <div className='btn-affichage-menu-fonction'>
                        <button onClick={() => setContenuPageCentrale(<GestionPrestations />)}>Gestion des Prestations</button>
                        </div>
                </fieldset>}

                {retourneVraieFaux(tableMenu.personnels) && <fieldset>
                    <legend>Personnels</legend>
                    <div className='btn-affichage-menu-fonction'>
                        <button onClick={() => setContenuPageCentrale(<GestionEmployes />)}>
                            Gestion des Personnels
                        </button>
                        </div>
                </fieldset>}
               
            </div>
           
            
        </div>
    );
};

export default MenuFonction;
