import React from 'react'
import { useMonContexte } from '../MonContext';
import './informationPersonnel.css'
import GestionEmployes from './GestionEmployes';
import SupprimerPersonnel from './SupprimerPersonnel';
import SaisirHorairePersonnel from '../Calendrier/SaisirHorairePersonnel';
import Planning from '../Calendrier/Planning';
import AjoutPersonnel from './AjoutPersonnel';

const InformationPersonnel = () => {


    const {setContenuPageCentrale, fichePersonnelSelect} =  useMonContexte();


  return (
    
    <div className="bloc-affich-personnel">
         <div className='text-fonction-menu'>
            Personnel:
        </div>
        <div className='bloc-fiche-ident'>                
                <div className='champ-affichage-selection-client'>
                    Nom: <span>{fichePersonnelSelect.nom}</span>
                </div>
                <div className='champ-affichage-selection-client'>
                    Prénom: <span>{fichePersonnelSelect.prenom}</span>
                </div>
                <div className='champ-affichage-selection-client'>
                    Fonction:<span>{fichePersonnelSelect.fonction}</span> 
                </div>
        </div>
        <div className='bloc-btn-gestion-personnel'> 
            <div className='btn-affichage-selection-personnel'>
                <button onClick={()=>setContenuPageCentrale(<SaisirHorairePersonnel/>)}>Modifier les horaires</button>
            </div>
            <div className='btn-affichage-selection-personnel'>
                <button onClick={()=>setContenuPageCentrale(<Planning/>)}>Planning</button>
            </div>
            <div className='btn-affichage-selection-personnel'>
                <button onClick={()=>setContenuPageCentrale(<GestionEmployes/>)}>Encours</button>
            </div>
            <div className='btn-affichage-selection-personnel'>
                <button onClick={()=>setContenuPageCentrale(<AjoutPersonnel id={fichePersonnelSelect.id}/>)}>Modifier Fiche Employé</button>
            </div>
            <div className='btn-affichage-selection-personnel'>
                <button onClick={()=>setContenuPageCentrale(<SupprimerPersonnel/>)}>Supprimer Personnel</button>
            </div>
        </div>
         
    </div> 
   
  )
}

export default InformationPersonnel