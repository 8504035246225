
// presentationDesk
import React from 'react'
import alyce from '../assets/alyce2.png'
import './presentationPhone.css'
import vernis from '../assets/img-vernis-ongle.jpg'
import outils from '../assets/img-outils.jpg'


const PresentationPhone = () => {

  return (
    <div className='container-composant'>
    
        <div className='container-image'>
            <img src={alyce} alt='img1' />
        </div>
        <div className='container-contenu-text'>  
            <div className='container-texte'>
                        <h1 className='paragraphe1'> Alyce Nail's, Ô Pays des Nails! Nous serons ravis de vous accueillir 
                                    dans notre univers dédié à la beauté des ongles.
                        </h1>
            </div>
            <div className='container-bloc1'>           
                    <div className='bloc1-image'>
                        <img className='vernis' src={vernis} alt='img-vernis' />
                    </div>  

                    <div className='bloc1-texte'>
                        <h1 className='paragraphe2'>Crée récemment, Ô Pays des Nails  est le fruit de la passion et de 
                            l'expertise d'Alyce, une professionnelle chevronnée de la prothèsie ongulaire. Forte de plus 
                            de 10 années d'expérience, Alyce a consacré sa carrière à offrir à ses clientes une expérience 
                            unique, alliant savoir-faire technique et service personnalisé.
                        </h1>
                    </div>  
            </div>
                
            <div className='container-bloc2'> 
                    <div className='bloc2-image'>
                        <img className='outils' src={outils} alt='img-outils' />
                    </div>
                    <div className='bloc2-texte'>
                        <h1 className='paragraphe2'>Explorez notre site pour découvrir nos services, notre galerie inspirante et nos conseils beauté. 
                            N'hésitez pas à nous contacter pour prendre rendez-vous ou poser vos questions. 
                        </h1>
                    </div>

            </div>
        </div>
    </div>
  )
}

export default PresentationPhone