import React from 'react'
import './App.css';
import MonContext from './MonContext';
import Webnail from './SiteWeb/Webnail';

function App() {


  return ( 
    <MonContext>
      <Webnail/>    
    </MonContext>
  );
}
export default App;
